import axios from "axios"
import {STRAPI_URL } from './axios'
import qs from 'qs'

export const loginSrv = async (account, password) => {
  const authBody = {
    identifier: account,
    password: password,
  };

  try {
    const respUser = await axios.post(STRAPI_URL + `/auth/local`, authBody)
    if(respUser.status == 200) {
      
      if(!respUser['data'] && !respUser['data']['jwt']) {
        return false
      }

      const jwt = respUser['data']['jwt']

      if(respUser['data']['user']) {
        const queryRole = qs.stringify({
          populate: ['role'],
          fields: ['id', 'username', 'email', 'createdAt'],
        });

        const respRole = await axios.get(STRAPI_URL + `/users/me?${queryRole}`, { headers: { Authorization: "Bearer " + jwt } })

        if (respRole.status == 200) {
          let respRoleData = respRole['data']
          respRoleData["jwt"] = jwt

          return respRoleData
        }
        
      }
    }
  
    return false
  } catch (e) {

    return e
  }
  
}

export const loginViaSSO = async (account) => {
  const query = qs.stringify({
    filters: {
      username: {
        $eq: account
      }
    },
    fields: ['id', 'username'],
  });

  const respUser = await axiosInstance.get(STRAPI_URL + `users?${query}`).catch(e => {
    console.log(e)
  })

  if(respUser.status == 200) {
    
    if(respUser['data'] && respUser['data'].length > 0) {
      const queryRole = qs.stringify({
        populate: ['role'],
        fields: ['id', 'username'],
      });

      const id = respUser['data'][0]['id']
      const respRole = await axiosInstance.get(STRAPI_URL + `users/${id}?${queryRole}`)

      console.log(respRole)
      if (respRole.status == 200) {
        return respRole['data']
      }
      
    }
  }

  return false
}

export const getOTPByEmail = async (email, type, scope) => {

  //do precheck existing valid record
  const query = qs.stringify({
    filters: {
      email: {
        $eq: email
      },
      expiryAt: {
        $gt: new Date()
      }
    },
    fields: ['email', 'expiryAt', 'scope'],
  });

  const existResp = await axiosInstance.get(STRAPI_URL + `otp-records?${query}`)
  if(existResp.status == 200 && existResp.data) {
    if(existResp['data']['data'].length > 0) {
      return {exist: true, record: existResp['data']['data'][0]}
    }
  }

  const payload = { data : {
      email,
      type,
      scope
    }
  }

  const resp = await axiosInstance.post(STRAPI_URL + `otp-records`, payload)
  if(resp && resp.data) {
    return {exist: false, record: resp.data}
  }

  return {exist: false, record: false}
}

export const verifyOTPByEmail = async (email, otp) => {
  const query = qs.stringify({
    filters: {
      email: {
        $eq: email
      },
      otp: {
        $eq: otp
      }
    },
    fields: ['email', 'expiryAt', 'scope'],
  });

  const resp = await axiosInstance.get(STRAPI_URL + `otp-records?${query}`)

  if(resp && resp['status'] == 200) {
    return resp['data']['data']
  } 

  return false
}
