import axios from "axios"

export const STRAPI_URL = "https://one.oitapac.com/strapi/api"

const axiosInstance = axios.create({
    baseURL: STRAPI_URL,
    timeout: 10000, //ms
    headers: {
        'Content-Type': 'application/json'
    }
})

// Request interceptor to add the access token to headers
axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = JSON.parse(localStorage.getItem('userInfo'))['jwt'];
      if (accessToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

// Response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem("userInfo")
            location.href="login"

        } else if (error.response.status === 403) {
            location.href="/?error=403"
        }
        return Promise.reject(error);
      }
)

export default axiosInstance