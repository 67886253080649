import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Select, Input, Radio, Progress, Button, Divider, Notification, Switch, Tooltip, Space, Loading, Steps, InputNumber, Tag } from 'tdesign-react';

import Layout from '../../layouts/Layout'

const { FormItem } = Form;
const { StepItem } = Steps;

const COUNTRY_CODES = [
  { label: 'Mexico', value: 'mx' },
  { label: 'Australia', value: 'au' },
  { label: 'Brazil', value: 'br' },
  { label: 'New Zealand', value: 'nz' },
  { label: 'Iraq', value: 'iq' },
]

export default ({ title, authMode, children }) => {
  const navigate = useNavigate()

  const [step, setStep] = useState(2)

  const [form] = Form.useForm()

  const onSubmit = () => { setStep(step + 1) }


  return (<Layout title={title} authMode={authMode}>
    <br />
    <div style={{ width: '100%', backgroundColor: '#f9f9f9', padding: '5px 15px', display: 'flex', justifyContent: 'center' }}>
      <Steps defaultCurrent={step} style={{ margin: '5px 15px' }}>
        <StepItem title="Create Netest Request" ><span onClick={() => { console.log(step); setStep(0) }}></span></StepItem>
        <StepItem title="IP Ping Progress" onClick={() => { setStep(1) }} />
        <StepItem title="Netest Report" onClick={() => { setStep(2) }} />
      </Steps>
    </div>
    <Form form={form} onSubmit={onSubmit} labelWidth={100} style={{ padding: '15px 15%', margin: '0 5px', backgroundColor: '#f9f9f9', minHeight: '300px', display: step == 0 ? 'block' : 'none' }}>
      <FormItem label="Netest Project" name="projectname">
        <Input />
      </FormItem>
      <FormItem label="Country " name="country" labelAlign="left" rules={[{ required: true, message: 'Required', type: 'warning' }]} style={{ width: '460px' }}>
        <Select clearable>
          {COUNTRY_CODES.map((item, index) => (
            <Select.Option value={item.value} label={item.label} key={index} />
          ))}
        </Select>
      </FormItem>
      <FormItem label="ISP Ratio" name="ipsmix">
        <Switch />
      </FormItem>
      <FormItem label="City Filter" name="cityfilter">
        <Switch />
      </FormItem>
      <FormItem label="Total IP" name="totalIp">
        <InputNumber defaultValue={10000} step={1000} max={50000} onChange={(v) => console.log(v)} />
      </FormItem>
      <br />
      <br />
      <br />
      <FormItem style={{ marginLeft: 100 }}>
        <Space>
          <Button type="submit" theme="primary">
            Next
          </Button>
        </Space>
      </FormItem>
    </Form>
    <Space direction='vertical' style={{ alignItems: 'center', margin: '5px', display: step == 1 ? 'flex' : 'none' }}>
      <div>Progress: <Tag theme="primary" variant="outline">3,276,000/5,040,000 Pings</Tag></div>
      <Progress theme={'circle'} label={<div>65%</div>} percentage={65}></Progress>
    </Space>
    <Space direction='vertical' style={{ alignItems: 'center', margin: '5px', display: step == 2 ? 'flex' : 'none' }}>
      <Notification title="NetTest Report" content={<div><p>Please check the link below</p><a href="#">https://netspeed.oitapac.com/d/JT103TyVk/aoe?orgId=1&var-rundate=2023-05-04</a></div>} theme="info" />
    </Space>

  </Layout>)
}
