import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Radio, Checkbox, Button, Divider, MessagePlugin, DatePicker, Select, Space, Loading } from 'tdesign-react';
import Layout from '../../layouts/Layout'

import SignatureCanvas from 'react-signature-canvas'
import './OnboardingForm.css'

import {
  getRecordsByTenId,
  updateRecord
} from '../../services/boardrecords';
import { departments } from '../../metadata/general'


const { FormItem } = Form;

const deptOptions = departments

import { ASSET_TYPES, ASSET_TYPES_OPS } from '../../metadata/assets'

export default ({ title, authMode, children }) => {

  const navigate = useNavigate()

  const searchParams = new URLSearchParams(window.location.search);
  const tencentId_init = searchParams.get('tencentId')

  const [form] = Form.useForm();
  const [record, setRecord] = useState({})
  const [assets, setAssets] = useState([])

  const id = Form.useWatch('id', form)
  const tencentId = Form.useWatch('tencentId', form)
  const boardingday = Form.useWatch('boardingday', form)
  const dept = Form.useWatch('dept', form)
  const staffPass = Form.useWatch('staffPass', form)
  const pedestalKey = Form.useWatch('pedestalKey', form)
  const accessCard = Form.useWatch('accessCard', form)

  const loginInfo = localStorage.getItem("userInfo")
  const userInfo = JSON.parse(loginInfo)
  const oitUser = userInfo['username']

  //check if user onboarded
  useEffect(() => {
    let fetched = false
    const fetchData = async () => {
      const resp = await getRecordsByTenId(tencentId_init) //data.filter(e => e['tencentId'] == tencentId)

      if (!fetched && resp.length > 0) {
        setRecord(resp[0] || {})
        setAssets(resp[0]['attributes']['assets'])
      }
    }

    fetchData()

    return () => { fetched = true; }

  }, [tencentId_init])

  let sigPadUser = {}
  let sigPadIT = {}
  let sigPadHR = {}
  let sigPadAdmin = {}
  const clearSigUser = () => {
    sigPadUser.clear()
  }
  const clearSigIT = () => {
    sigPadIT.clear()
  }
  const clearSigHR = () => {
    sigPadHR.clear()
  }
  const clearSigAdmin = () => {
    sigPadAdmin.clear()
  }

  const onSubmit = async (e) => {
    //check required
    if (!dept) {
      MessagePlugin.error("Please choose your department");
      return
    }

    const newAssets = []
    for (let i = 0; i < assets.length; i++) {
      if (form.getFieldValue(`sn-${i}`) || form.getFieldValue(`model-${i}`)) {
        newAssets.push({
          type: form.getFieldValue(`type-${i}`),
          model: form.getFieldValue(`model-${i}`),
          serialNumber: form.getFieldValue(`sn-${i}`),
          returned: form.getFieldValue(`returned-${i}`),
        })
      }
    }

    const payload = {
      data: {
        tencentId: tencentId,
        onboardingDate: boardingday,
        department: dept,
        assets: newAssets,
        staffPass,
        pedestalKey,
        accessCard,
        oitUser,
      }
    }

    const resp = await updateRecord(id, payload)

    if (resp.status == 200) {
      MessagePlugin.info("Done");
      navigate("/")
    } else {
      MessagePlugin.error("Fail to save");
    }

  };

  const handleAssetAdd = () => {
    let newGroup = [...assets]

    newGroup = newGroup.concat([{ type: "Laptop", model: "", serialNumber: "" }])

    setAssets(newGroup)
  }

  const handleAssetRemove = (i) => {
    let newGroup = [...assets]

    newGroup = newGroup.filter((_, idx) => { return idx != i })

    setAssets(newGroup)
  }

  let content = (<Form form={form}>
    <div style={{ textAlign: 'center' }}>
      <p>No record found</p><br />
      <Button theme="danger" variant="base">
        <Link to="/"> Return to Home Page</Link>
      </Button>
    </div>
  </Form>)

  if (Object.keys(record).length > 0) {
    const todayDt = new Date()
    const { id, attributes } = record

    const lastDay_init = attributes['offboardingDate']
    const dept_init = attributes['department'] || ""
    const onSignatures = attributes['onSignatures'] || {}
    const offSignatures = attributes['offSignatures'] || {}

    content = <Form form={form} onSubmit={onSubmit} labelWidth={100} style={{ padding: '15px 15%', }}>
      <FormItem label="ID" name="id" initialData={id} style={{ width: '50%', display: 'none' }}>
        <Input readonly placeholder='' />
      </FormItem>
      <div style={{ border: "solid 1px #b4b3c0bf", padding: "5px 10px", borderRadius: "10px", boxShadow: "rgb(33 33 52 / 10%) 0px 1px 4px" }}>
        <FormItem label="Tencent ID" name="tencentId" labelAlign="left" initialData={tencentId_init} rules={[{ required: true, message: 'Required', type: 'warning' }]}>
          <Input placeholder='tencent ID' readonly style={{ width: "320px" }} />
        </FormItem>

        <FormItem label="First Day" labelAlign='left' name="firstday" initialData={attributes['onboardingDate']}>
          <DatePicker mode="date" placeholder='' />
        </FormItem>
        <FormItem label="Last Day" labelAlign='left' name="lastday" initialData={lastDay_init}>
          <DatePicker mode="date" placeholder='' />
        </FormItem>

        <FormItem label="Department" labelAlign='left' name="dept" initialData={dept_init}>
          <Radio.Group options={deptOptions} ></Radio.Group>
        </FormItem>
      </div>
      <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9" }}>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>IT Endorsement (Device received)</span>
      </Divider>

      {assets.map((_, idx) => <div key={_['id']} style={{ border: "solid 1px #b4b3c0bf", margin: "5px 0", padding: "5px", borderRadius: "10px", boxShadow: "rgb(33 33 52 / 10%) 0px 1px 4px" }}>
        <Space size="small">
          <FormItem key={`type-${idx}`} label="Type" name={`type-${idx}`} style={{ width: '100%' }} initialData={_['type']}>
            <Select options={ASSET_TYPES_OPS} />
          </FormItem>

          <FormItem label={`Model`} name={`model-${idx}`} style={{ width: '100%' }} initialData={_['model']}>
            <Input placeholder='' />
          </FormItem>
          <FormItem label={`SN`} name={`sn-${idx}`} style={{ width: '100%' }} initialData={_['serialNumber']}>
            <Input placeholder='serial number' />
          </FormItem>
          <FormItem label={`Returned`} name={`returned-${idx}`} style={{ width: '100%' }} initialData={_['returned']}>
            <Checkbox />
          </FormItem>
        </Space>

        <Space size={"large"} style={{ marginTop: "5px" }}>
          <FormItem label=" " style={{ float: "right" }}>
            <Button size="small" type="button" variant="base" theme='danger' onClick={() => setAssets(assets.filter(item => item['id'] != _['id']))}>Remove</Button>
          </FormItem>
        </Space>
      </div>)}

      <Space direction='horizontal' style={{ float: "right" }}>
        <FormItem label=" ">
          <Button size="medium" type="button" variant="base" theme="success" onClick={handleAssetAdd}>Add</Button>
        </FormItem>
      </Space>
      <br />

      <div className='signature-panel'>
        <p>User Onboarding Signature</p>
        <img src={onSignatures['UserSignature']} width="240px" height="140px"></img>
      </div>

      <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9" }}>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>IT Endorsement (Offboarding Signatures)</span>
      </Divider>
      <Space direction='horizontal'>
        <div className='signature-panel'>
          <p>User Signature</p>
          {offSignatures['UserSignature'] ? <img src={offSignatures['UserSignature']}></img> : <>
            <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadUser = ref }}
              canvasProps={{ width: 210, height: 120, className: 'sigCanvas' }} />
            <div>
              <Button size='small' variant="outline" theme="dashed" onClick={clearSigUser}>Clear</Button>
            </div>
          </>
          }
        </div>
        <div className='signature-panel'>
          <p>IT Signature</p>
          {offSignatures['ITSignature'] ? <img src={offSignatures['ITSignature']}></img> : <>
            <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadIT = ref }}
              canvasProps={{ width: 210, height: 120, className: 'sigCanvas' }} />
            <div>
              <Button size='small' variant="outline" theme="dashed" onClick={clearSigIT}>Clear</Button>
            </div></>
          }
        </div>
      </Space>

      <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9" }}>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>Admin Endorsement (Staff Pass and Access Card)</span>
      </Divider>
      <Space direction='horizontal'>
        <FormItem label="" name="staffPass" initialData={attributes['staffPass']}>
          <Checkbox /> &nbsp;&nbsp;Staff Pass
        </FormItem>
        <FormItem label="" name="accessCard" initialData={attributes['accessCard']}>
          <Checkbox />&nbsp;&nbsp;Access Card
        </FormItem>
        <FormItem label="" name="pedestalKey" initialData={attributes['pedestalKey']}>
          <Checkbox />&nbsp;&nbsp;Pedestal Keys
        </FormItem>
      </Space>


      <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9" }}>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>Admin/HR Offboarding Signatures</span>
      </Divider>

      <Space direction="horizontal">
        <div className='signature-panel'>
          <p>Admin Signature</p>
          {offSignatures['AdminSignature'] ? <img src={offSignatures['AdminSignature']}></img> : <>
            <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadAdmin = ref }}
              canvasProps={{ width: 210, height: 120, className: 'sigCanvas' }} />
            <div>
              <Button size='small' variant="outline" theme="dashed" onClick={clearSigAdmin}>Clear</Button>
            </div>
          </>
          }
        </div>
        <div className='signature-panel' style={{ display: "none" }}>
          <p>HR Signature</p>
          {offSignatures['HRSignature'] ? <img src={offSignatures['HRSignature']}></img> : <>
            <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadHR = ref }}
              canvasProps={{ width: 210, height: 120, className: 'sigCanvas' }} />
            <div>
              <Button size='small' variant="outline" theme="dashed" onClick={clearSigHR}>Clear</Button>
            </div>
          </>
          }
        </div>

      </Space>

      <Divider></Divider>
      <FormItem style={{ marginLeft: 100 }}>
        <Space>
          <Button type="submit" theme="primary">
            Submit
          </Button>
          <Button variant='outline' onClick={() => { navigate("/boardrecords") }}>
            Cancel
          </Button>
        </Space>
      </FormItem>
    </Form>
  }

  return (<Layout title={title} authMode={authMode}>
    {content}
  </Layout>);
}
