import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from "react-router-dom";
import MaterialReactTable from 'material-react-table';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Layout from '../../layouts/Layout'


import {
  getOITQSRecords
} from '../../services/oitqsrecord';
import { Textarea, Drawer, Button, MessagePlugin } from 'tdesign-react';

export default ({ title, authMode, children }) => {
  const navigate = useNavigate()
  const [records, setRecords] = useState([])
  const [drawerItem, setDrawerItem] = useState("");
  const [clipboardData, setClipboardData] = useState("");

  const todayDt = new Date()

  useEffect(() => {
    let fetched = false
    const fetchData = async () => {
      const resp = await getOITQSRecords()

      if (!fetched) {
        setRecords(resp)
      }
    }

    fetchData()

    return () => { fetched = true; }

  }, [])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'tencentId', //access nested data with dot notation
        header: 'Tencent Id',
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At',
        Cell: ({ cell }) => <span>{new Date(cell.getValue()).toLocaleString()}</span>,
      },
      {
        accessorKey: 'machineType', //normal accessorKey
        header: 'Device Type',
      },
      {
        accessorKey: 'serialNumber',
        header: 'SN',
      },
      {
        accessorKey: 'brand',
        header: 'Brand',
      },
      {
        accessorKey: 'keySpecs',
        header: 'Specs',
      },
      {
        accessorKey: 'installationLog',
        header: 'Log',
        Cell: ({ cell }) => <Textarea rows={3} readonly value={cell.getValue() ?? ''} onClick={() => setDrawerItem(cell.getValue())} />,
      },
    ],
    [],
  );

  const onCopy = () => {
    setClipboardData(drawerItem)
    MessagePlugin("success", "Copied")
  }

  return (
    <Layout title={title} authMode={authMode}>
      <div style={{ padding: "10px 15px" }}>
        <MaterialReactTable columns={columns} data={records} />

        <Drawer header={`Installation Log: `}
          visible={drawerItem}
          size="medium"
          onClose={() => setDrawerItem("")}
          cancelBtn={"Close"}
          closeBtn={false}
          confirmBtn={<CopyToClipboard onCopy={onCopy} text={clipboardData}><Button>Copy</Button></CopyToClipboard>}
          onConfirm={onCopy}
        >
          <div>{drawerItem}</div>
        </Drawer>
      </div>
    </Layout>);
}
