import axiosInstance from './axios'
import { STRAPI_URL } from './axios'
import qs from 'qs'

export const getSpeedTests = async (page, pageSize) => {

  const queryObj = {
    sort: ['start_dt:desc'],
    populate: "*",
    pagination: {
      'page': page, // starts from 0
      'pageSize': pageSize,
      'withCount': true,
    },
  }

  const query = qs.stringify(queryObj);

  const resp = await axiosInstance.get(`/speedtests?${query}`)
  console.log(resp)

  if (resp.status == 200) {
    if (resp['data'] && resp['data']['data'] && resp['data']['data'].length > 0) {
      const data = resp['data']['data'].map(_ => Object.assign({ id: _.id }, _.attributes))
      const totalRecords = resp['data']['meta']['pagination']['total']
      // return both the data and the total records
      return [data, totalRecords]
    }
  }

  // if not 200, data is invalid
  return [[], 0]

}


export const createSpeedTest = async (body) => {
  const resp = await axiosInstance.post(`/speedtests/`, body)
  return resp
}


export const updateSpeedTest = async (id, body) => {
  const resp = await axiosInstance.put(`/speedtests/${id}`, body)
  return resp
}

export const unpublishSpeedTest = async (id) => {

  const resp = await axiosInstance.put(`/speedtests/${id}`, { 'data': { publishedAt: null } })
  return resp
}
