import { createBrowserRouter, useNavigate } from 'react-router-dom';

import { AUTH_MODE_BASIC, AUTH_MODE_PUBLIC } from '../metadata/authMode';

import Home from '../screens/Home/Home';

import Login from '../screens/Login/Login'
import OTPLogin from '../screens/Login/OTPLogin'
import PostLogin from '../screens/Login/PostLogin';

import NetworkStatusBoard from '../screens/ITOps/NetworkStatusBoard';

import OPDCTicketForm from '../screens/OPDC/OPDCTicketForm';
import OITQSRecord from '../screens/ITOps/OITQSRecords';

import BoardingRecords from '../screens/ITOps/BoardingRecords';
import EditBoardForm from '../screens/ITOps/EditBoardForm';
import OffboardingForm from '../screens/ITOps/OffboardingForm';
import OnboardingForm from '../screens/ITOps/OnboardingForm';

import WifiQrGenForm from '../screens/ITOps/WifiQrGenForm';
import IPzhua from '../screens/Speedtest/IPzhua';
import Netest from '../screens/Speedtest/Netest';
import SpeedTest from '../screens/Speedtest/SpeedTest';

// route definition
// note that throughout the web app, when navigating, you should use this
// object to reference the path name instead of typing it out in string
// this will make the paths of the pages easier to change if needed to be changed
//
// Good Example: 
// import { ROUTES } './lib/router'
// const navigate = useNavigate()
// navigate(ROUTES.LOGIN.path)
//
// Bad Example: 
// const navigate = useNavigate()
// navigate('/login')
export const ROUTES = {
  HOME: {
    path: '/',
    element: <Home title="HOME" authMode={AUTH_MODE_BASIC} ></Home>,
  },

  // Login/Auth --------------------------------------------------------------
  LOGIN: {
    path: "/login",
    element: <Login />,
  },
  OTP_LOGIN: {
    path: "/otplogin",
    element: <OTPLogin />,
  },
  POST_LOGIN: {
    path: '/auth/oidc/callback',
    element: <PostLogin authMode={AUTH_MODE_BASIC} />
  },

  // Public ------------------------------------------------------------------
  SYSTEM_HEALTH: {
    path: "/syshealth",
    element: <NetworkStatusBoard title="Network Devices Status" authMode={AUTH_MODE_BASIC}></NetworkStatusBoard>
  },

  // Boarding ----------------------------------------------------------------
  BOARD_RECORDS: {
    path: "/boardrecords",
    element: <BoardingRecords title="Boarding Records" authMode={AUTH_MODE_BASIC}></BoardingRecords>,
  },
  BOARD_ONBOARD: {
    path: "/onboard",
    element: <OnboardingForm title="Onboarding Form" authMode={AUTH_MODE_BASIC}></OnboardingForm>,
  },
  BOARD_EDIT: {
    path: "/editboard",
    element: <EditBoardForm title="Edit Board Record" authMode={AUTH_MODE_BASIC}></EditBoardForm>,
  },
  BOARD_OFFBOARD: {
    path: "/offboard",
    element: <OffboardingForm title="Offboarding Form" authMode={AUTH_MODE_BASIC}></OffboardingForm>,
  },

  // ODPC --------------------------------------------------------------------
  ODPC: {
    path: "/opdc",
    element: <OPDCTicketForm title="隔离区工单" authMode={AUTH_MODE_BASIC}></OPDCTicketForm>,
  },

  // OITQS -------------------------------------------------------------------
  OITQS: {
    path: "/oitqsrecords",
    element: <OITQSRecord title="OITQS Records" authMode={AUTH_MODE_BASIC}></OITQSRecord>
  },

  WIFI_QR: {
    path: "/wifiqrgen",
    element: <WifiQrGenForm title="WiFi QR Generator" authMode={AUTH_MODE_BASIC}></WifiQrGenForm>
  },

  IP_ZHUA: {
    path: '/ipzhua',
    element: <IPzhua title="IP Gather" authMode={AUTH_MODE_BASIC} />
  },
  SPEED_TEST: {
    path: '/speedtest',
    element: <SpeedTest title="Speed Test" authMode={AUTH_MODE_BASIC} />
  },
  NET_TEST: {
    path: '/netest',
    element: <Netest title="Netspeed Test" authMode={AUTH_MODE_BASIC} />
  }

}


// public access to all users.
// all the routes you add to this array will be accessible by
// all users regardless of them being authenticated or not
const PUBLIC_ACCESS = [
  ROUTES.LOGIN.path,
  ROUTES.POST_LOGIN.path,
  ROUTES.OTP_LOGIN.path,
  ROUTES.HOME.path,
]

// all routes added to this array will be accessible by
// authenticated users without specific role
const AUTHENTICATED_ACCESS = [

]

// all access to the entire site without restrictions
const ALL_ACCESS = Object.keys(ROUTES).map(x => ROUTES[x].path)

// ROLES_ACCESS_MAP defines the routes that a 
// specific role can access. 
//
// This approach is the role first approach, 
// each role having an array of routes that are 
// accessible by the role.
//
// If you want a Route first approach i.e 
// {
// '/ipzhua': [role_a, role_b]
// }
// you can use the generateRouteAccessMap function below
export const ROLE_ACCESS_MAP = {

  'public': PUBLIC_ACCESS,

  'authenticated': AUTHENTICATED_ACCESS,

  'oitall': ALL_ACCESS,

  'oitcep': [
    ROUTES.IP_ZHUA.path,
    ROUTES.SPEED_TEST.path,
  ],

  'oitsre': [
    ROUTES.ODPC.path
  ],

  // it ops access
  'oitops': [
    ROUTES.BOARD_RECORDS.path,
    ROUTES.BOARD_ONBOARD.path,
    ROUTES.BOARD_OFFBOARD.path,
    ROUTES.SYSTEM_HEALTH.path,

    ROUTES.OITQS.path,
    ROUTES.WIFI_QR.path,
  ],

}


// generates a route first access map based on the role based one above
// e.g 
// {
//   '/ipzhua': [role_a, role_b]
// }
export const generateRouteAccessMap = () => {
  let ROUTE_ACCESS_MAP = {}

  // for every route
  Object.keys(ROUTES).map(routeKey => {

    const routePath = ROUTES[routeKey].path

    // if empty, set to empty arr
    ROUTE_ACCESS_MAP[routePath] = ROUTE_ACCESS_MAP[routePath] ?? []

    // iterate through all roles 
    Object.keys(ROLE_ACCESS_MAP).map((roleKey) => {

      // if the route is in the role access map, include it
      if (ROLE_ACCESS_MAP[roleKey].includes(ROUTES[routeKey].path)) {
        ROUTE_ACCESS_MAP[routePath].push(roleKey);
      }
    })
  })

  return ROUTE_ACCESS_MAP
}

export const router = createBrowserRouter(Object.keys(ROUTES).map(x => {
  return ROUTES[x]
}));
