import QRCode from 'qrcode'

export const wifiQrGenerator = async (config) => {
  let retVal = `WIFI:S:${config.ssid};P:${config.password};H:${config.hiddenSSID};`
  if (config.encryption !== 'None') {
    retVal += `T:${config.encryption};`
  }

  return await QRCode.toDataURL(retVal,
    {
      type: "image/png",
      width: 400,
      color: {
        dark: "#0052d9",
        light: "#fff"
      }
    })
}
