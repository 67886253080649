import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Select, Table, Input, Progress, Button, Notification, Switch, Space, MessagePlugin, InputNumber, Tag, Steps } from 'tdesign-react';
import { InternetIcon, ErrorCircleFilledIcon, CheckCircleFilledIcon, CloudDownloadIcon, ScanIcon } from 'tdesign-icons-react';
import Layout from '../../layouts/Layout'

import { createPresignedUrlWithClient, } from '../../lib/s3Broker'
import { getZhuaTasks, updateZhuaTask, createZhuaTask, getCompletedAsnFromS3, getIspData as getAsnData } from '../../services/ipzhua'
import { formatGenericNumber } from '../../lib/format'
import { getCountryByCountryCode, getCountryCodeList } from '../../metadata/country'

const { FormItem } = Form;
const { StepItem } = Steps;
const COUNTRY_CODE_LIST = getCountryCodeList()

const statusNameListMap = {
  0: { label: 'Finished', theme: 'success', icon: <CheckCircleFilledIcon /> },
  1: { label: 'In Progress', theme: 'warning', icon: <ErrorCircleFilledIcon /> },
};
const asnData = getAsnData()

export default ({ title, authMode, children }) => {
  const navigate = useNavigate()

  // states
  const [data, setData] = useState([])
  const [step, setStep] = useState(-1)
  const [isLoading, setIsLoading] = useState(true)
  const [tableFilter, setTableFilter] = useState({
    country: ''
  })

  // form
  const [form] = Form.useForm()
  const itemCountry = Form.useWatch('itemCountry', form)
  const itemStatefilter = Form.useWatch('itemStatefilter', form)
  const itemStates = Form.useWatch('itemStates', form)
  const itemCityfilter = Form.useWatch('itemCityfilter', form)
  const itemCities = Form.useWatch('itemCities', form)
  const itemISPRatio = Form.useWatch('itemISPRatio', form)
  const totalIp = Form.useWatch('totalIp', form)

  // calculate row current progress
  const getRowProgressPercent = async (row) => {
    // if the job is not running, its considered completed
    if (row.end_dt && row.start_dt && row.end_dt >= row.start_dt) {
      return 100
    }

    // country code and start date of the current row
    const countryCode = row.country_code
    const startDate = row.start_dt

    // get the list of asn that are in the country, and the list of asn that have completed
    const completedAsnList = await getCompletedAsnFromS3(countryCode, startDate)
    const totalAsnSet = asnData[countryCode]

    // get number of completed asn from bucket
    const completed_asn_count = completedAsnList.length;
    const total_asn_count = totalAsnSet.size

    // calculate progress percentage according to how many finished
    const progress_percent = completed_asn_count / total_asn_count * 100
    return progress_percent.toFixed(0);
  }


  // initState
  useEffect(() => {
    const fetchData = async () => {
      const resp = await getZhuaTasks()

      // get all progress concurrently
      let postData = await Promise.all(
        resp.map(async _ => {
          return {
            id: _.id,
            country: getCountryByCountryCode(_.country_code),
            country_code: _.country_code,
            // 0: not running, 1: running
            status: _.end_dt && _.start_dt && _.end_dt >= _.start_dt ? 0 : 1,
            progress: await getRowProgressPercent(_),
            totalIPs: _.total_ip || 0,
            processdate: _.latest_dt,
            lastJobDate: _.start_dt,
            lastSamplingDate: _.sampling_dt.substr(0,10),
            by_isp_ratio: _.by_isp_ratio ?? false,
          }
        })
      )
      //sort by status In Progress first then latest_dt
      postData = postData.sort((x, y) => {
        if (x.status == 1) {
          return -1
        }
        return x.processdate > y.processdate ? -1 : 1
      })
      setData(postData)
      setIsLoading(false)

    }
    fetchData()

  }, [])


  const onSubmit = async (e) => {
    if (e.validateResult === true) {

      //check existing record
      const getResp = await getZhuaTasks(itemCountry)

      // if exist, update record to scan ip
      if (Array.isArray(getResp) && getResp.length > 0) {
        const record = getResp[0]

        //check if ip job is within 1 week
        const weekago = new Date(Date.now() - 604800000);

        // start > end means there is an ongoing task
        if (record.start_dt > record.end_dt) {
          MessagePlugin('info', "We have ongoing IP scanning task!")
          return
        }

        let body = {
          data: {
            state_filter: itemStates,
            city_filter: itemCities,
            by_isp_ratio: itemISPRatio,
            sampling_number: totalIp
          }
        }

        //if the latest run was earlier than a week ago, run a new job (set start date)
        if (new Date(record.latest_dt) <= weekago) {
          body.data.start_dt = new Date().toISOString().split('T')[0]
        }

        //body.data.start_dt = new Date().toISOString().split('T')[0]
        const updateResp = await updateZhuaTask(record.id, body)
        if (updateResp) {
          const message = `Task updated${body.data.start_dt ? `, running new task as latest task was more than a week ago (${record.latest_dt})` : ""}`
          console.log(message)
          MessagePlugin('success', message)
          location.reload()
        }
      }

      // if not, create a new record and start ip scanning
      else {
        const body = {
          data: {
            country_code: itemCountry,
            start_dt: new Date().toISOString().split('T')[0],
            state_filter: itemStates,
            city_filter: itemCities,
            by_isp_ratio: itemISPRatio,
          }
        }
        const createResp = await createZhuaTask(body)
        if (createResp) {
          MessagePlugin('success', "Task created!")
          location.reload()
        }
      }
    }
  }

  const onDownloadClick = async (row) => {
    let fileKey = `liveips/${row.country_code}/${row.processdate}/sampling_ip_new_10000.txt`

    const resp = await createPresignedUrlWithClient(fileKey)

    window.open(resp)
  }

  const onCountryChange = async (value) => {
    const resp = await getZhuaTasks(value)
    console.log(resp)
    if (Array.isArray(resp) && resp.length > 0) {
      const record = resp[0]
      form.setFieldsValue({
        'totalIp': record['sampling_number'] ?? 10000,
        'itemStates': record['state_filter'],
        'itemCities': record['city_filter'],
        'itemISPRatio': record['by_isp_ratio'] ?? false,
      })
    }
  }

  // only one value for now, can change next time
  const onFilterChange = async (filters, col) => {
    setTableFilter(filters)
  }

  const columns = [
    {
      colKey: 'country',
      title: 'Country',
      ellipsis: true,
      filter: {
        type: 'input',
        resetValue: '',
        props: {
          placeholder: 'Filter'
        },
      },
    },
    {
      colKey: 'status',
      title: 'Status',
      cell: ({ row }) => (
        <div style={{ width: 200, display: 'inline-block' }} >
          <Progress label percentage={row.progress ?? 0} size="medium" theme="line" />
        </div>
      ),
    },
    {
      title: 'Total IPs',
      colKey: 'totalIPs',
      //align: 'right',
      ellipsisTitle: true,
      cell: ({ row }) => (
        <span> {formatGenericNumber(row.totalIPs.toString())} </span>
      ),
    },
    {
      title: 'Last Updated Date',
      colKey: 'processdate',
      // 支持同时设置 tooltipProps 和 浮层内容,
      ellipsis: {
        props: {
          theme: 'light',
          placement: 'bottom-right',
        },
        content: ({ row }) => (
          <div>
            <p>
              <b>Request Date:</b> {row.processdate}
            </p>
          </div>
        ),
      },
    },
    {
      title: 'Last Sampling Date',
      colKey: 'lastSamplingDate',
      // 支持同时设置 tooltipProps 和 浮层内容,
      ellipsis: {
        props: {
          theme: 'light',
          placement: 'bottom-right',
        },
        content: ({ row }) => (
          <div>
            <p>
              {row.lastSamplingDate}
            </p>
          </div>
        ),
      },
    },
    {
      title: 'Download Samples',
      colKey: 'link',
      align: 'center',
      // 注意这种 JSX 写法需设置 <script lang="jsx" setup>
      cell: ({ row }) => (row.totalIPs > 0 ? <Button shape="circle" onClick={() => { onDownloadClick(row) }} icon={<CloudDownloadIcon />} /> : ''),
    },
  ];
  // filter data 
  const filtered = data.filter((item) => { return item['country']?.toLowerCase().indexOf(tableFilter["country"]?.toLowerCase()) > -1 })

  return (
    <Layout title={title} authMode={authMode}>
      {/** list of IPs part */}
      <div style={{ display: step < 0 ? 'block' : 'none' }}>
        <h2 style={{ margin: 'auto', margin: '15px', fontSize: '32px', fontWeight: 800, textAlign: 'center' }}><Button variant='outline' size='large' ><InternetIcon /> &nbsp;  Available IP List</Button></h2>
        <Button style={{ marginLeft: '10%' }} onClick={() => { setStep(0) }}>New Request</Button>
        <Table rowKey="index"
          data={filtered}
          columns={columns}
          filterValue={tableFilter}
          onFilterChange={onFilterChange}
          loading={isLoading}
          pagination={{
            defaultCurrent: 1,
            defaultPageSize: 10,
            total: filtered.length,
            onChange(pageInfo) {
              console.log(pageInfo, 'onChange pageInfo');
            },
            onCurrentChange(current, pageInfo) {
              console.log(current, pageInfo, 'onCurrentChange current');
            },
            onPageSizeChange(size, pageInfo) {
              console.log(size, pageInfo, 'onPageSizeChange size');
            },
          }}
          style={{ display: step < 0 ? 'block' : 'none', width: '80%', margin: 'auto', marginTop: '10px' }} />
      </div>
      <br />
      <div style={{ width: '100%', backgroundColor:'#f9f9f9', padding: '5px 15px', display: step > -1? 'flex': 'none', justifyContent:'center' }}>
            <Steps defaultCurrent={step} style={{margin: '5px 15px'}}>
                <StepItem title="Choose a country" ><span onClick={() => {console.log(step);setStep(0)}}></span></StepItem>
                <StepItem title="IP address gathering" onClick={() => {setStep(1)}}  />
                <StepItem title="Receive IP report" onClick={() => {setStep(2)}}  />
            </Steps>
        </div>

      {/** submit new zhua task part */}
      <h2 style={{ margin: 'auto', margin: '15px', fontSize: '32px', fontWeight: 800, textAlign: 'center', display: step == 0 ? 'block' : 'none' }}><Button variant='outline' size='large' ><ScanIcon /> &nbsp; IP Scan Task</Button></h2>
      <Form form={form} onSubmit={onSubmit} labelWidth={150} style={{ padding: '15px 15%', margin: '0 5px', backgroundColor: '#f9f9f9', minHeight: '300px', display: step == 0 ? 'block' : 'none' }}>
        <FormItem label="Country" name="itemCountry" labelAlign="left" rules={[{ required: true, message: 'Required', type: 'warning' }]} style={{ width: '460px' }}>
          <Select clearable filterable onChange={onCountryChange}>
            {
              COUNTRY_CODE_LIST.map((item, index) => (
                <Select.Option value={item.value} label={item.label} key={index} />
              ))
            }
          </Select>
        </FormItem>
        <FormItem labelAlign="left" label="Filter by State" name="itemStatefilter" initialData={false}>
          <Switch />
        </FormItem>
        <FormItem labelAlign="left" label="States" name="itemStates" style={{ display: itemStatefilter ? 'block' : 'none' }}>
          <Input placeholder='input state/province name using "," as delimiter'></Input>
        </FormItem>
        <FormItem labelAlign="left" label="Filter by City" name="itemCityfilter" initialData={false}>
          <Switch />
        </FormItem>
        <FormItem labelAlign="left" label="Cities" name="itemCities" style={{ display: itemCityfilter ? 'block' : 'none' }}>
          <Input placeholder='input city name using "," as delimiter'></Input>
        </FormItem>
        <FormItem labelAlign="left" label="Filter by ISP Ratio" name="itemISPRatio" initialData={false}>
          <Switch />
        </FormItem>
        <FormItem label="Total IP" name="totalIp" initialData={10000}  >
          <InputNumber step={1000} max={50000} min={0} onChange={
            v => form.setFieldsValue({
              'totalIp': v,
            })
          } />
        </FormItem>
        <br />
        <br />
        <br />
        <FormItem style={{ marginLeft: 100 }}>
          <Space>
            <Button type="button" theme="warning" onClick={() => { setStep(step - 1) }}>
              Cancel
            </Button>
            <Button type="submit" theme="primary">
              Submit
            </Button>
          </Space>
        </FormItem>
      </Form>

      <Space direction='vertical' style={{ alignItems: 'center', margin: '5px', display: step == 100 ? 'flex' : 'none' }}>
        <div>Total IP Detected: <Tag theme="primary" variant="outline">721,299</Tag></div>
        <Progress theme={'circle'} label={<div>75%</div>} percentage={75}></Progress>
      </Space>
      <Space direction='vertical' style={{ alignItems: 'center', margin: '5px', display: step == 200 ? 'flex' : 'none' }}>
        <Notification title="IP Report" content={<div><p>Please check the link below, and it will expire in 24 hours</p><a href="#">https://ap-sourtheast-1.s3.aws.app/au_ip_output_20230504.csv</a></div>} theme="info" />
      </Space>

    </Layout>)
}
