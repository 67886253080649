import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Card, Space, Tag, Popconfirm, MessagePlugin } from 'tdesign-react';
import { EditIcon, UserClearIcon } from 'tdesign-icons-react';
import Input from 'tdesign-react/es/input/Input';
import MaterialReactTable from 'material-react-table';
import Layout from '../../layouts/Layout'

import {
  getBoardingRecords,
  deleteRecord
} from '../../services/boardrecords';

import { ROUTES } from '../../lib/router';

export default ({ title, authMode, children }) => {
  const navigate = useNavigate()
  const [records, setRecords] = useState([])
  const [joinerId, setJoinerId] = useState()

  const todayDt = new Date()

  useEffect(() => {
    let fetched = false
    const fetchData = async () => {
      const resp = await getBoardingRecords()

      if (!fetched) {
        setRecords(resp)
      }
    }

    fetchData()

    return () => { fetched = true; }

  }, [])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'tencentId', //access nested data with dot notation
        header: 'Tencent Id',
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At',
        Cell: ({ cell }) => <span>{new Date(cell.getValue()).toLocaleString()}</span>,
      },
      {
        accessorKey: 'department', //normal accessorKey
        header: 'Department',
      },
      {
        accessorKey: 'onboardingDate',
        header: 'Onboarding Date',
        Cell: ({ cell }) => <span>{cell.getValue()}</span>,
        size: 120
      },
      {
        accessorKey: 'offboardingDate',
        header: 'Offboarding Date',
        Cell: ({ cell }) => <span>{cell.getValue()}</span>,
        size: 120
      },
      {
        accessorKey: 'accessCard',
        filterVariant: 'checkbox',
        header: 'Access Card',
        Cell: ({ cell }) => { return cell.getValue() ? <Tag theme='warning'>Not Returned</Tag> : <Tag theme='success'>Returned</Tag> },
      },
      {
        accessorKey: 'staffPass',
        filterVariant: 'checkbox',
        header: 'Staff Pass',
        Cell: ({ cell }) => { return cell.getValue() ? <Tag theme='warning'>Not Returned</Tag> : <Tag theme='success'>Returned</Tag> },
      },
      {
        accessorKey: 'assets',
        header: 'Assets',
        Cell: ({ cell }) => cell.getValue().map((_, idx) => <Card key={"asset-" + idx} bordered hoverShadow shadow size="small" style={{ margin: "2px 0" }}>
          {_.model}: {_.serialNumber}
        </Card>),
        filterFn: (row, id, filterValue) => {
          return row.getValue(id).some((_, idx) => {
            let result = false
            if (_['serialNumber']) {
              result = _['serialNumber'].indexOf(filterValue) > -1
            }

            if (_['model']) {
              result = _['model'].indexOf(filterValue) > -1
            }

            return result
          })
        },
        size: 240
      },
    ],
    [],
  );

  const deleteClickHandler = async (recordId) => {
    await deleteRecord(recordId)
    window.location.reload(false);
  }

  return (
    <Layout title={title} authMode={authMode}>
      <div style={{ padding: "15px 20px" }}>
        <Space style={{ float: "left", margin: "15px", }} direction="horizontal">
          <Popconfirm content={<Input value={joinerId} placeholder='new joiner tencentId' onChange={(val => setJoinerId(val))} />} theme={'primary'}
            confirmBtn={
              <Button size={'small'} onClick={() => {
                if (!joinerId) {
                  MessagePlugin("error", "tencent id must not be empty")
                  return false
                }
                navigate(`${ROUTES.BOARD_ONBOARD.path}?tencentId=${joinerId}`)
              }} >
                Confirm
              </Button>
            }
            cancelBtn={<Button size={'small'} variant='outline' >Cancel</Button>}
            onCancel={() => {

            }}>
            <Button onClick={() => { }} style={{ cursor: "pointer" }}>Onboard New</Button>
          </Popconfirm>
        </Space>

        <MaterialReactTable columns={columns} data={records} initialState={{ columnVisibility: { id: false } }} isLoading={!fetched}
          enableRowActions
          renderRowActions={({ cell, row, table }) => <Space direction='vertical'>

            <Button variant='outline' theme='primary' onClick={() => { navigate(`${ROUTES.BOARD_EDIT.path}?tencentId=${row.getValue("tencentId")}`) }}><EditIcon /> </Button>

            {/* <Popconfirm content={'Confirm to delete record for ' + row.getValue("tencentId")} theme={'danger'} 
                confirmBtn={
                  <Button size={'small'} onClick={() => deleteClickHandler(row.getValue("id"))} >
                    Confirm
                  </Button>
                }
                cancelBtn={<Button size={'small'} variant='outline' >Cancel</Button>}
                onCancel={() => {
                  
                }}
                
                >
                <Button theme='danger'><DeleteIcon /> </Button>
              </Popconfirm> */}

            <Button theme="warning" onClick={() => { navigate(`${ROUTES.BOARD_OFFBOARD.path}?tencentId=${row.getValue("tencentId")}`) }} style={{ display: row.getValue("offboardingDate") ? "none" : "inline" }}><UserClearIcon /></Button>
          </Space>
          }
          positionActionsColumn="last"
        />
      </div>
    </Layout>)
}
