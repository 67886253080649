export const formatGenericNumber = (numStr) => {
  if(numStr)
    return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return
}

export const formatToLocalDate = (date) => {
  let dateObj = new Date(date)
  const offset = dateObj.getTimezoneOffset()
  dateObj = new Date(dateObj.getTime() - (offset * 60 * 1000))
  return dateObj.toISOString()
}
