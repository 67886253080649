import React, { useEffect } from 'react';
import { Form, Input, Button, MessagePlugin, Row, Divider, Space, Col, Layout } from 'tdesign-react';
import { DesktopIcon, LockOnIcon } from 'tdesign-icons-react';
import { useNavigate } from "react-router-dom";

import { loginSrv } from '../../services/auth'

import { UserManager } from 'oidc-client'

const { FormItem } = Form;

export default function BaseForm() {
  const [form] = Form.useForm()

  const searchParams = new URLSearchParams(window.location.search)
  const originate = searchParams.get('originate') || location.origin

  const account = Form.useWatch("account", form)
  const password = Form.useWatch("password", form)

  useEffect(() => {
    // listen for enter key to submit form
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        console.log('enter key pressed')
        onEnterKeyPressed()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [])

  const onEnterKeyPressed = async (e) => {
    if (!account || !password) {
      MessagePlugin.error('user name or password cannot be emptry');
    } else {
      const loginResult = await loginSrv(account, password)
      
      if(loginResult && loginResult['response'] && loginResult['response']['status'] != 200) {
        const err = loginResult['response']['data']['error']
        MessagePlugin.error(`${err['message']} `);
      } else if(loginResult) {
        MessagePlugin.info('Login Successfully');

        localStorage.setItem("userInfo", JSON.stringify(loginResult))

        location.href = originate
      }  else {
        MessagePlugin.error(`Unknown Error! Contact shusonwang via wecom.`);
      }
    }
  };

  // sso login
  const oidcClient = new UserManager({
    authority: 'https:/${ROUTES.LOGIN.path}.iam.intlgame.com/sso/tn-869bbe512bb54c14921d85c69ae839dc/ai-5d81c448bc6d4d50b59c5e15d5b400fa/oidc/.well-known/openid-configuration',
    client_id: 'ai-5d81c448bc6d4d50b59c5e15d5b400fa',
    redirect_uri: 'http://localhost:3000/auth/oidc/callback',
    response_type: 'id_token',
    automaticSilentRenew: false
  });


  const userInfo = localStorage.getItem("userInfo")
  if (userInfo) {
    location.href = originate
  }

  return (
    <Layout style={{ height: '100vh', backgroundColor: "#eee" }}>
      <div className='login-form' style={{"boxShadow": "-8px -5px 34px -7px rgba(2,4,1,0.75)", padding: "4px", backgroundColor: "#fff"}}>
        <Divider><span style={{ fontWeight: 700, fontSize: '1.2rem' }}>Welcome</span></Divider>
        <Form form={form} statusIcon={true} onSubmit={onEnterKeyPressed} colon={true} labelWidth={0} style={{margin: "3%"}}>
          <FormItem name="account" rules={[{ required: true, message: 'required', type: 'error' }]} >
            <Input clearable={true} prefixIcon={<DesktopIcon />} placeholder="User Name" onEnter={onEnterKeyPressed} />
          </FormItem>
          <FormItem name="password" rules={[{ required: true, message: 'required', type: 'error' }]}>
            <Input type="password" prefixIcon={<LockOnIcon />} clearable={true} placeholder="Password" onEnter={onEnterKeyPressed} />
          </FormItem>
          <Row direction='horizontal' align="center">
            <Col span={2}>
              <FormItem>
                <Button theme="primary" type="submit">
                  Login
                </Button>

              </FormItem>
            </Col>
          </Row>
          {/* <Divider>Or</Divider>
          <Row direction='horizontal' align="center">
            <Col span={2}></Col>
            <Col span={6}>
             <FormItem>
                <Button theme="primary" type="button" onClick={() => {
                  oidcClient.signinRedirect()
                }}>
                  SSO Login &nbsp; <img src={'/yufu.png'} width="60px" style={{backgroundColor: 'white', padding: "2px"}}></img>
                </Button>
                
              </FormItem>
            </Col>
          </Row> */}
        </Form>
      </div>
    </Layout>
  );
}
