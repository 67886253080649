import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Radio, Checkbox, Button, Divider, MessagePlugin, DatePicker, Tooltip, Space, Loading } from 'tdesign-react';
import Layout from '../../layouts/Layout'

import './OPDCTicketForm.css'

import { departments } from '../../metadata/general'
import Textarea from 'tdesign-react/es/textarea/Textarea';
import { ROUTES } from '../../lib/router';


const { FormItem } = Form;

const issueOptions = [
  { label: '海外iOA相关', value: 'ioa' },
  { label: 'Linux/Windows跳板机', value: 'bastion' },
  { label: '智能网关配置相关', value: 'smartgate' },
  { label: '软硬Token相关', value: 'token' },
  { label: '其他', value: 'others' },
];

const deptOptions = departments

export default ({ title, authMode, children }) => {

  const navigate = useNavigate()

  const searchParams = new URLSearchParams(window.location.search);
  const tencentId_init = searchParams.get('tencentId')

  const todayDt = new Date()
  const lastDay_init = todayDt.toISOString().substring(0, 10)

  const [form] = Form.useForm();
  const tencentId = Form.useWatch('tencentId', form)
  const filingDate = Form.useWatch('filingDate', form)
  const dept = Form.useWatch('dept', form)
  const issueType = Form.useWatch('issueType', form)
  const issueDescription = Form.useWatch('issueDescription', form)
  const attachment = Form.useWatch('attachment', form)

  const onSubmit = async (e) => {

    const resp = { status: 200 } //await createRecord(payload)

    if (resp.status == 200) {
      MessagePlugin.info("Done");
      navigate(ROUTES.HOME.path)
    } else {
      MessagePlugin.error("Fail to save");
    }

  };

  let content = (<Form form={form} onSubmit={onSubmit} colon labelWidth={100} style={{ padding: '15px 15%', }}>

    <FormItem label="Tencent ID" name="tencentId" initialData={tencentId_init} style={{ width: '50%' }}>
      <Input placeholder='tencent ID' />
    </FormItem>

    <FormItem label="Date" name="filingDate" initialData={lastDay_init}>
      <DatePicker mode="date" placeholder='' />
    </FormItem>

    <FormItem label="Department" name="dept">
      <Radio.Group options={deptOptions} ></Radio.Group>
    </FormItem>
    <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9" }}>
      <span style={{ fontWeight: 700, fontSize: '16px' }}>Issue Details</span>
    </Divider>

    <FormItem label="Issue Type" name="issueType">
      <Radio.Group options={issueOptions} ></Radio.Group>
    </FormItem>
    <FormItem label={`Description`} name={`issueDescription`}>
      <Textarea autosize={{ minRows: 3, maxRows: 20 }} maxLength="200" />
    </FormItem>
    <br />
    <br />
    <FormItem style={{ marginLeft: 100 }}>
      <Space>
        <Button type="submit" theme="primary">
          Submit
        </Button>
      </Space>
    </FormItem>
  </Form>
  )


  return (
    <Layout title={title} authMode={authMode}>
      {content}
    </Layout>);
}
