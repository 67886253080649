import axiosInstance from './axios'
import qs from 'qs'

export const getOITQSRecords = async () => {
  let result = []
  //only return recent 1 year data

  const currentDate = new Date();
  // Subtract one year from the current date
  const oneYearAgo = new Date(currentDate);
  oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

  const query = qs.stringify({
    populate: "*",
    pagination: {'limit':1000},
    sort: ['id:desc'],
    filters: {
      createdAt: {
        $gt: oneYearAgo.toISOString()
      }
    }
  });

  const resp = await axiosInstance.get(`/oitqs-records?${query}`)

  if (resp.status == 200) {

    if (resp.data && resp.data['data'].length > 0) {
      result = resp['data']['data'].map(_ => {
        let obj = _.attributes
        obj['id'] = _.id

        return obj
      })
    }
  }

  return result

}