import { useEffect, useState } from 'react';
import { Collapse, Button, Table, Form, Card, DatePicker, Input, Textarea, InputNumber, Tag, Select, Link, DialogPlugin } from 'tdesign-react';
import { ErrorCircleFilledIcon, CheckCircleFilledIcon, TimeFilledIcon } from 'tdesign-icons-react';
import Row from 'tdesign-react/es/grid/Row';
import { AddIcon, DeleteIcon } from 'tdesign-icons-react'
import Layout from '../../layouts/Layout';
import { getServerProviderList } from '../../metadata/serverProvider'
import { getServerCountryList, getCountryByAnyForm, getCountryByIso3, getIso3ByIso2, getRegionList } from '../../metadata/country';
import { createSpeedTest, unpublishSpeedTest, getSpeedTests, updateSpeedTest } from '../../services/speedtest'
import { getIpCount } from '../../services/ipzhua';
import { formatGenericNumber, formatToLocalDate } from '../../lib/format';

const FormItem = Form.FormItem;
const { Panel } = Collapse;
const SERVER_PROVIDER_LIST = getServerProviderList()
const SERVER_COUNTRY_LIST = getServerCountryList()
const REGION_LIST = getRegionList()

// global 
let cardIdCounter = 1;

export default ({ title, authMode, children }) => {

  // useState -----------------------------------------------------------------

  const [data, setData] = useState([]); // table data
  const [ipByIsoMap, setIpByIsoMap] = useState({}) // ipzhua total_ips data
  const [isLoading, setIsLoading] = useState(true) // loading icon

  const [expandedRowKeys, setExpandedRowKeys] = useState([]); // keys of the rows that are expanded

  const [toggleForm, setToggleForm] = useState(false) // when true, shows the form
  const [togglePanel, setTogglePanel] = useState(false) // when true, toggles the ping panel in form
  const [cards, setCards] = useState([{ id: 0 }]) // cards to represent the servers in the form
  const [updateRequestId, setUpdateRequestId] = useState(-1) // id of the record to update

  const [currPage, setCurrPage] = useState(1) // current page 
  const [pageSize, setPageSize] = useState(10) // number of records per page
  const [total, setTotal] = useState(0) // total records

  const [pagination, setPagination] = useState({ // pagination object for tdesign table
    current: currPage,
    pageSize: pageSize,
    total: total,
    maxPageBtn: 5,
    onCurrentChange: (newPage, pageInfo) => {
      setCurrPage(newPage)
    },
    onPageSizeChange: (newSize, pageInfo) => {
      setPageSize(newSize)
    },
  });
  // useForm ------------------------------------------------------------------
  const [form] = Form.useForm()

  // Fetch -------------------------------------------------------------------
  const fetchSpeedTest = async (currPage, pageSize) => {
    const [speedTestRes, total] = await getSpeedTests(currPage, pageSize)

    // get all the country codes related to the results
    const iso3List = speedTestRes.map(x => {
      return x.servers.map(y => {
        // get the iso 3 data
        return getIso3ByIso2(y.target_country)
      })
    }).flat()

    //remove duplicates
    const uniqueIso3List = [...new Set(iso3List)]

    const ipCountRes = await getIpCount(uniqueIso3List)

    let isoMap = {}
    ipCountRes.map(x => {
      isoMap[x.country_code] = x.total_ip
    })
    setIpByIsoMap(isoMap)

    // update state for data
    setData(speedTestRes)
    setTotal(total)
    setPagination({
      current: currPage,
      pageSize: pageSize,
      total: total,
      maxPageBtn: 5,
      onCurrentChange: (newPage, pageInfo) => {
        setCurrPage(newPage)
      },
      onPageSizeChange: (newSize, pageInfo) => {
        setPageSize(newSize)
      },
    })
    setIsLoading(false)
  }

  // useEffect ----------------------------------------------------------------
  // listen to all page related states trigger reload of data
  useEffect(() => {
    setIsLoading(true)
    fetchSpeedTest(currPage, pageSize)
  }, [currPage, pageSize])

  // when the cards state changes, update the form's value for the card
  useEffect(() => {
    cards.map((x, i) => {
      form.setFieldsValue(x)
    })

  }, [cards])

  // Table Actions -----------------------------------------------------------
  const onNewSpeedTestClick = (value) => {
    setToggleForm(!toggleForm)
    setCards([{ id: 0 }])
    const update = {
      formPurpose: '',
      formStartDate: new Date().toISOString().split('T')[0],
      formPingInterval: 1,
      formPingTimeout: 4000,
      formPingTimes: 4,
      formPingSize: 64,
    }
    form.setFieldsValue(update)
    cardIdCounter = 1
  }

  const onEditClick = (row) => {
    setToggleForm(!toggleForm)
    setUpdateRequestId(row.id)
    const update = {
      formProjectName: row['project_name'],
      formPurpose: row['purpose'],
      formStartDate: formatToLocalDate(row['start_dt']),
      formPingInterval: row['ping_interval'],
      formPingTimeout: row['ping_timeout'],
      formPingTimes: row['ping_times'],
      formPingSize: row['ping_size'],
    }
    form.setFieldsValue(update)

    let updatedCards = []
    row.servers.map((x, i) => {
      // update cards
      updatedCards.push({
        id: cardIdCounter++,
        [`serverProvider-${i}`]: x['server_provider'],
        [`serverCity-${i}`]: x['server_city'],
        [`serverCountry-${i}`]: x['server_country'],
        [`serverIp-${i}`]: x['server_ip'],
        [`serverRegion-${i}`]: x['server_region'],
        [`serverTargetCountry-${i}`]: x['target_country'],
      })
    })
    setCards(updatedCards)


  }

  // handle expand row
  const handleExpandRow = (value, params) => {
    setExpandedRowKeys(value);
  };

  // Form Actions -------------------------------------------------------------
  const onSubmitForm = async (e) => {
    // if the form is valid 
    if (e.validateResult && e.validateResult === true) {
      const userInfo = localStorage.getItem("userInfo")
      if (!userInfo) {
        // invalid login, bring them to login page

      }
      const userInfoObj = JSON.parse(userInfo)

      const allFields = form.getFieldsValue(true);

      // object to post/put with 
      let params = {}

      // format form fields to request params in strapi
      params['project_name'] = allFields.formProjectName
      params['purpose'] = allFields.formPurpose
      params['start_dt'] = new Date(allFields.formStartDate).toISOString()
      params['ping_interval'] = allFields.formPingInterval
      params['ping_timeout'] = allFields.formPingTimeout
      params['ping_times'] = allFields.formPingTimes
      params['ping_size'] = allFields.formPingSize
      params['requested_by'] = userInfoObj.username
      params['servers'] = []

      // format nested server form
      for (let i = 0; i < cardIdCounter; i++) {
        // a reference a form field to an attribute
        const currIndexFieldNames = {
          [`serverProvider-${i}`]: 'server_provider',
          [`serverCity-${i}`]: 'server_city',
          [`serverCountry-${i}`]: 'server_country',
          [`serverIp-${i}`]: 'server_ip',
          [`serverRegion-${i}`]: 'server_region',
          //[`serverTargetCountry-${i}`]: 'target_country',
        }

        if((`serverTargetCountry-${i}` in allFields) == false) {
          continue
        }

        if(Array.isArray(allFields[`serverTargetCountry-${i}`])) {
          for(let v of allFields[`serverTargetCountry-${i}`]) {
            // temp variable to store the formatted result
            let params_server = {
              'target_country': v
            }
  
            // iterate through all the fields in the form, and map them to their request attribute names
            for (let f in currIndexFieldNames) {
  
              // if the field isn't undefined
              if (allFields[f]) {
                params_server[currIndexFieldNames[f]] = allFields[f]
              }
  
            }
  
            // add to the server array
            if (Object.keys(params_server).length > 0) {
              params['servers'].push(params_server)
            }
          }       
        } else {
          let params_server = {
            'target_country': allFields[`serverTargetCountry-${i}`]
          }

          // iterate through all the fields in the form, and map them to their request attribute names
          for (let f in currIndexFieldNames) {

            // if the field isn't undefined
            if (allFields[f]) {
              params_server[currIndexFieldNames[f]] = allFields[f]
            }

          }

          // add to the server array
          if (Object.keys(params_server).length > 0) {
            params['servers'].push(params_server)
          }
        }
      }

      let response;
      //debugger
      //make request to create/update
      if (updateRequestId === -1) {
        response = await createSpeedTest({ data: params })
      }
      else {
        response = await updateSpeedTest(updateRequestId, { data: params })
      }

      setIsLoading(true)
      fetchSpeedTest(currPage, pageSize)
      setTogglePanel(false)
      setToggleForm(!toggleForm)
      setUpdateRequestId(-1)
    }
  }

  const onDeleteForm = async () => {
    const confirmDia = DialogPlugin.confirm({
      header: 'Delete Speed Test',
      body: 'Are you sure you want to delete the Speed Test?',
      confirmBtn: 'Delete',
      cancelBtn: 'Cancel',
      onConfirm: async ({ e }) => {
        const resp = await unpublishSpeedTest(updateRequestId)

        setIsLoading(true)
        fetchSpeedTest(currPage, pageSize)
        setTogglePanel(false)
        setToggleForm(!toggleForm)
        setUpdateRequestId(-1)

        confirmDia.hide();
      },
      onClose: async ({ e, trigger }) => {
        confirmDia.hide();
      },
    });
  }


  const onCancelForm = (e) => {
    setTogglePanel(false)
    setToggleForm(!toggleForm)
    setUpdateRequestId(-1)
  }

  // Form Cards -------------------------------------------------------------
  const onAddFormCard = (e) => {
    setCards([...cards, { id: cardIdCounter++ }])
  }

  const onDeleteFormCard = (i) => {
    let newCards = cards.slice()
    newCards.splice(i, 1)
    setCards(newCards)
    cardIdCounter--
  }

  //  Table Metadata ---------------------------------------------------------
  const statusNameListMap = {
    0: { label: 'Completed', theme: 'success', icon: <CheckCircleFilledIcon /> },
    1: { label: 'Queued', theme: 'warning', icon: <TimeFilledIcon /> },
    2: { label: 'In Progress', theme: 'warning', icon: <ErrorCircleFilledIcon /> },
  };
  const columns = [
    {
      colKey: 'project_name',
      title: 'Project Name'
    },
    {
      colKey: 'purpose',
      title: 'Purpose',
    },
    {
      colKey: 'serial_number',
      title: 'Status',
      cell: ({ row }) => {
        let status = 0

        // if the start date is more recent than the end date -> re-running
        // if the end date doesn't exist -> new job not finished yet
        if (!row.end_dt || row.start_dt > row.end_dt) {

          // if it has not finished, and the datetime now is more recent than the start_dt -> it is running but has not completed
          if (new Date() >= row.start_dt) {
            status = 2
          }
          // otherwise it is considered queued
          else {
            status = 1
          }
        }
        return <Tag
          shape="round"
          theme={statusNameListMap[status].theme}
          variant="light-outline"
          icon={statusNameListMap[status].icon}
        >
          {statusNameListMap[status].label}
        </Tag >
      },
    },
    {
      colKey: 'start_dt',
      title: 'Latest Date',
      cell: ({ row }) => {
        if (!row.end_dt) {
          return formatToLocalDate(row.start_dt).split('T')[0]
        }
        return row.start_dt > row.end_dt ? formatToLocalDate(row.start_dt).split('T')[0] : formatToLocalDate(row.end_dt).split('T')[0]
      }
    },
    {
      colKey: 'link',
      title: 'Edit',
      width: 150,
      cell: ({ row }) => {
        return <Link theme="primary" hover="color" onClick={(e) => {
          e.preventDefault()
          onEditClick(row)
        }}> Edit </Link>
      }

    },

  ];

  const expandedColumns = [
    {
      colKey: 'server_provider',
      title: 'Server Provider'
    },
    {
      colKey: 'server_ip',
      title: 'Server IP'
    },
    {
      colKey: 'server_region',
      title: 'Server Region'
    },
    {
      colKey: 'server_country',
      title: 'Server Country',
      cell: ({ row }) => {
        return getCountryByAnyForm(row.server_country)
      }
    },
    {
      colKey: 'server_city',
      title: 'Server City'
    },
    {
      colKey: 'target_country',
      title: 'Target Country',
      cell: ({ row }) => {
        return getCountryByIso3(row.target_country)
      }
    },
    {
      colKey: 'serial_number',
      title: 'Total IPs',
      cell: ({ row }) => {
        const total_ips = ipByIsoMap[row.target_country]
        return formatGenericNumber(total_ips)
      }
    },
  ]

  // Components --------------------------------------------------------------
  return (
    <Layout title={title} authMode={authMode}>
      <div style={{ margin: 20 }} >
        <div style={{ display: !toggleForm ? 'none' : 'block' }}>
          <Form form={form} onSubmit={onSubmitForm} labelWidth={150} >
            <FormItem label="Project Name" rules={[{ required: true }]} labelAlign='right' name="formProjectName"  >
              <Input placeholder='' />
            </FormItem>

            <FormItem label="Purpose" name="formPurpose" initialData={''}>
              <Textarea
                placeholder=''
                allowInputOverMax={false}
                autofocus={false}
                autosize={false}
                readonly={false}
              />
            </FormItem>

            <FormItem label="Start Date" labelAlign='right' name="formStartDate" initialData={new Date().toISOString().split('T')[0]}>
              <DatePicker enableTimePicker placeholder='' allowInput />
            </FormItem>

            <Collapse value={togglePanel} onChange={setTogglePanel} style={{ marginBottom: '10px' }}>
              <Panel header={'Ping Parameters (Optional)'} >
                <div className="tdesign-demo-block-column" style={{ width: '60%' }}>

                  <FormItem label="Ping Time" labelAlign='right' name="formPingTimes" initialData={4}>
                    <InputNumber />
                  </FormItem>

                  <FormItem label="Ping Timeout" labelAlign='right' name="formPingTimeout" initialData={4000}>
                    <InputNumber />
                  </FormItem>

                  <FormItem label="Ping Size" labelAlign='right' name="formPingSize" initialData={64}>
                    <InputNumber />
                  </FormItem>

                  <FormItem label="Ping Interval" labelAlign='right' name="formPingInterval" initialData={1}>
                    <InputNumber />
                  </FormItem>

                </div>
              </Panel></Collapse>
            {
              // if there are no cards currently, show the add new card icon
              cards.length <= 0 ? <AddNewCard /> : // otherwise show the cards
                <div >
                  {
                    cards.map((row, i) => {
                      return <div
                        key={i}
                        className='t-card--bordered'
                        style={{
                          marginBottom: '10px',
                          padding: '35px 20px',
                          background: 'white',
                          borderRadius: '5px',
                        }}

                      >

                        <Row align='end' style={{ marginBottom: '10px' }} >
                          <Button variant='outline' type='button' onClick={(e) => onDeleteFormCard(i)}>
                            <DeleteIcon />
                          </Button>
                        </Row>

                        <FormItem label="Server Provider" name={`serverProvider-${i}`} labelAlign="right" rules={[{ required: true, message: 'Required', type: 'warning' }]} initialData={''}>
                          <Select clearable filterable >
                            {
                              SERVER_PROVIDER_LIST.map((item, index) => {
                                return <Select.Option value={item} label={item} key={index} />
                              })
                            }
                          </Select>
                        </FormItem>

                        <FormItem label="Server IP" name={`serverIp-${i}`} labelAlign="right" initialData={''}>
                          <Input></Input>
                        </FormItem>
                        <FormItem label="Server Region" name={`serverRegion-${i}`} labelAlign="right" initialData={''}>
                          <Select clearable filterable>
                            {
                              REGION_LIST.map((item, index) => {
                                return <Select.Option value={item} label={item} key={index} />
                              })
                            }
                          </Select>
                        </FormItem>
                        <FormItem label="Server Country" name={`serverCountry-${i}`} labelAlign="right" initialData={''} >
                          <Select clearable filterable>
                            {
                              SERVER_COUNTRY_LIST.map((item, index) => {
                                return <Select.Option value={item.country} label={item.country} key={index} />
                              })
                            }
                          </Select>
                        </FormItem>
                        <FormItem label="Server City" name={`serverCity-${i}`} labelAlign="right" initialData={''}>
                          <Input></Input>
                        </FormItem>
                        <FormItem label="Target Country" name={`serverTargetCountry-${i}`} labelAlign="right"
                          rules={[{ required: true, message: 'Required', type: 'warning' }]} initialData={''}
                        >
                          <Select clearable filterable multiple>
                            {
                              SERVER_COUNTRY_LIST.map((item, index) => (
                                <Select.Option value={item.iso3} label={item.country} key={index} />
                              ))
                            }
                          </Select>
                        </FormItem>
                      </div>
                    })
                  }
                  <AddNewCard></AddNewCard>
                </div>
            }
            <br />
            <Row justify='space-between'>
              <div>
                <Button type='reset' theme="warning" style={{ marginRight: '10px' }} onClick={(e) => {
                  onCancelForm(e);
                }}>Cancel</Button>
                <Button type='submit' theme='primary' >{updateRequestId !== -1 ? "Update" : "Submit"}</Button>
              </div>
              {
                updateRequestId === -1 ? <></> :
                  <Button type='button' theme="danger" onClick={(e) => {
                    onDeleteForm()
                  }}>Delete</Button>
              }
            </Row>
          </Form>
        </div>

        <div style={{ display: toggleForm ? 'none' : 'block' }}>
          <Button onClick={onNewSpeedTestClick}>New Speed Test</Button>
          <Table
            style={{
              marginTop: '10px'
            }}
            rowKey="id"
            columns={columns}
            data={data}
            expandedRowKeys={expandedRowKeys}
            expandedRow={expandedRow} // expanded component
            expandIcon={true} // icon for expanding
            onExpandChange={handleExpandRow}
            loading={isLoading}
            pagination={pagination}
          />
        </div>
      </div >
      <br />
      <br />
      <br />
    </Layout >
  );


  function AddNewCard(props) {
    return <div onClick={(e) => onAddFormCard(e)} style={{ cursor: 'pointer' }}>
      <Card style={{
        textAlign: 'center',
        borderStyle: 'dashed',
        height: '150px',
        display: 'flex',
        placeItems: 'center',
        justifyContent: 'center'
      }} >
        <AddIcon size={15} />&nbsp;Add Test Server
      </Card>
    </div>
  }

  function expandedRow(props) {
    return <div>
      <Table
        data={props.row.servers}
        columns={expandedColumns}
        table-layouts="auto"
        bordered
      />
    </div>
  };
}


