import { listPrefixObjects } from '../lib/s3Broker'
import axiosInstance from './axios'
import qs from 'qs'


export const getZhuaTasks = async (country_code) => {
  const queryObj = {
    populate: "*",
    pagination: { 'limit': 1000 },
  }
  if (country_code) {
    queryObj.filters = {
      country_code: {
        $eq: country_code,
      }
    }
  }

  const query = qs.stringify(queryObj);

  const resp = await axiosInstance.get(`/ip-zhuas?${query}`)

  if (resp.status == 200) {
    if (resp['data'] && resp['data']['data'] && resp['data']['data'].length > 0) {
      const data = resp['data']['data'].map(_ => Object.assign({ id: _.id }, _.attributes))

      return data;
    }
  }

  return []

}

export const getIpCount = async (country_code_list) => {

  const queryObj = {
    filters: {
      country_code: {
        $in: country_code_list,
      }
    }
  }

  const query = qs.stringify(queryObj);

  const resp = await axiosInstance.get(`/ip-zhuas?${query}`)

  if (resp.status == 200) {
    if (resp['data'] && resp['data']['data'] && resp['data']['data'].length > 0) {
      const data = resp['data']['data'].map(_ => Object.assign({ id: _.id }, _.attributes))

      return data;
    }
  }

  return []
}


export const createZhuaTask = async (body) => {
  const resp = await axiosInstance.post(`/ip-zhuas`, body)

  return resp
}

export const updateZhuaTask = async (id, body) => {
  const resp = await axiosInstance.put(`/ip-zhuas/${id}`, body)

  return resp
}

export const getCompletedAsnFromS3 = async (countryCode, startDate) => {
  const prefix = `liveips/${countryCode}/${startDate}/`
  const resp = await listPrefixObjects(prefix)
  console.log(resp)

  // check if the prefix contains any objects
  if (resp.Contents && resp.Contents.length > 0) {

    const asnList = []
    resp.Contents.map(_ => {
      // remove the prefix from the full path and remove file extension
      let filename = _.Key.replace(prefix, '')
      filename = filename.substring(0, filename.indexOf('.'))

      // if its the sampling file, ignore it 
      if (filename.includes("sampling")) {
        return
      }

      // otherwise split the filename into its asn parts
      const fullList = filename.split("_")
      fullList.map(x => {
        if (x != "data" && x != "") {
          asnList.push(x)
        }
      })
    })
    return asnList
  }
  return []
}

export const getIspData = () => {
  let cleanedAsnData = {}
  const asnData = require('../metadata/asid.json')
  asnData.map(_ => {
    // if the key does exist, set it as an empty set first
    if (!cleanedAsnData[_.alpha3_code]) {
      cleanedAsnData[_.alpha3_code] = new Set()
    }
    // split the fixed_asid and mobile_asid, there may be multiple in 1 attribute
    // since its a set, duplicates will be ignored
    _.fixed_asid?.split(';').map(x => {
      cleanedAsnData[_.alpha3_code].add(x)
    })
    _.mobile_asid?.split(';').map(x => {
      cleanedAsnData[_.alpha3_code].add(x)
    })
  })
  return cleanedAsnData
}

