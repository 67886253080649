import axiosInstance from './axios'
import qs from 'qs'

export const getStatusData = async (id) => {
  const query = qs.stringify({
    populate: "*"
  });

  const resp = await axiosInstance.get(`/health-statuses?${query}`)

  if(resp.status == 200) {
    
    if(resp['data'] && resp['data'] && resp['data']['data'].length > 0) {
      var data = resp['data']['data'].filter(function(el){
        return el.id == id;
      })

      return data[0]['attributes']['devices'];
    }
  }

  return []

}
