import React, { useState } from 'react';
import { Form, Input, Button, MessagePlugin, Row, Divider, Layout, Col } from 'tdesign-react';
import { DesktopIcon, LockOnIcon } from 'tdesign-icons-react';

import { getOTPByEmail, verifyOTPByEmail } from '../../services/auth'
import { verifyEmail, verifyTencentEmail } from '../../lib/validation'

const { FormItem } = Form;

export default (props) => {
  const searchParams = new URLSearchParams(window.location.search)
  const target = searchParams.get('target')
  const originate = searchParams.get('originate') || location.origin

  const [form] = Form.useForm()

  const email = Form.useWatch("email", form)
  const otp = Form.useWatch("otp", form)

  const getToken = async (e) => {
    let scope = "Network"

    if (!email) {
      MessagePlugin.error('Email field cannot be emptry');
    } else if (target == "internal") {

      if (verifyTencentEmail(email)) {
        const { exist, record } = await getOTPByEmail(email, "Tencent", scope)
        if (exist) {
          MessagePlugin.info('Previous token is still valid, please check it in your mailbox')
        } else if (record) {
          MessagePlugin.info('Token sent successfully, please check your email')
        } else {
          MessagePlugin.error('Email is not valid');
        }
      } else {
        MessagePlugin.error('Email must be a Tencent one');
      }
    } else if (target == "external") {

      if (verifyEmail(email)) {
        const { exist, record } = await getOTPByEmail(email, "External", scope)
        if (exist) {
          MessagePlugin.info('Previous token is still valid, please check it in your mailbox')
        } else if (record) {
          MessagePlugin.info('Token sent successfully, please check your email')
        } else {
          MessagePlugin.error('Email is not valid');
        }
      } else {
        MessagePlugin.error('Email is not a valid one');
      }

    } else {
      MessagePlugin.error('Target service or email address is not within valid scope');
    }
  };

  const verifyToken = async (e) => {
    if (!otp) {
      MessagePlugin.error('Email and OTP cannot be emptry')
    } else {
      const resultResp = await verifyOTPByEmail(email, otp)
      if (resultResp.length > 0) {
        MessagePlugin.info('Login Successfully')

        localStorage.setItem("userInfo", JSON.stringify(resultResp[0]))

        location.href = originate
      } else {
        MessagePlugin.error('Email or OTP is not valid');
      }
    }
  }

  const userInfo = localStorage.getItem("userInfo")
  if (userInfo) {
    location.href = originate
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <div className='login-form'>
        <Divider><span style={{ fontWeight: 700, fontSize: '1.2rem' }}>Welcome</span></Divider>
        <Form form={form} statusIcon={true} colon={true} labelWidth={0} >
          <FormItem name="email" rules={[{ required: true, message: 'required', type: 'error' }]}>
            <Input clearable={true} prefixIcon={<DesktopIcon />} placeholder={target == "external" ? "example@non-tencent.com" : "example@tencent.com"} />
          </FormItem>
          <FormItem name="otp" rules={[{ required: true, message: 'required', type: 'error' }]}>
            <Input type="password" prefixIcon={<LockOnIcon />} clearable={true} placeholder="6 digits in your email" />
          </FormItem>

          <Row align="center">
            <Col span={4}>
              <FormItem>
                <Button theme="primary" type="button" onClick={getToken}>
                  Get OTP
                </Button>
              </FormItem>
            </Col>
            <Col span={2}></Col>
            <Col span={4}>
              <FormItem>
                <Button theme="primary" type="button" onClick={verifyToken}>
                  Verify
                </Button>
              </FormItem>

            </Col>


          </Row>
        </Form>
      </div>
    </Layout>
  );
}
