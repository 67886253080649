export const ASSET_TYPES = {
  LAPTOP: "Laptop",
  DESKTOP: "Desktop",
  MONITOR: "Monitor",
  ACCESSORIES: "Accessories",
  OTHERS: "Others"
}

export const ASSET_TYPES_OPS = [
  { label: "Laptop", value: "Laptop" },
  { label: "Desktop", value: "Desktop" },
  { label: "Monitor", value: "Monitor" },
  { label: "Accessories", value: "Accessories" },
  { label: "Others", value: "Others" },
]
