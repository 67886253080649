import React, { useState } from 'react';
import { Card, MessagePlugin, Space, Button, Row, Col } from 'tdesign-react';
import { useNavigate } from 'react-router-dom';
import { IconFont } from 'tdesign-icons-react';

import Layout from '../../layouts/Layout'
import './Home.css'

import { StatusCount, errorCount } from '../ITOps/NetworkStatusSummary';
import { getCurrentUserRole } from '../../lib/role';
import { ROUTES, generateRouteAccessMap } from '../../lib/router';


const Home = ({ title, authMode, children }) => {

  const navigate = useNavigate()
  const role = getCurrentUserRole()
  const ROUTE_ACCESS_MAP = generateRouteAccessMap()

  if(location.search == "?error=403") {
    MessagePlugin.error('user has no permission. contact shusonwang via wecom');
  }

  return <Layout title={title} authMode={authMode} >
    <div
      className="dashboard-grid"
      style={{
        display: 'grid',
        paddingBottom: "50px",
      }}>

      <DashboardCard
        cardTitle={"OITQS Records"}
        cardDescription={"Check OITQS records"}
        buttonText={"View"}
        onButtonClick={() => { navigate(ROUTES.OITQS.path) }}
        allowedRoles={ROUTE_ACCESS_MAP[ROUTES.OITQS.path]}
        role={role}
      >
      </DashboardCard>

      <DashboardCard
        cardTitle={"Generate WiFi QR Code"}
        cardDescription={"Generate a QR code for WiFi quick access"}
        buttonText={"View"}
        onButtonClick={() => { navigate(ROUTES.WIFI_QR.path) }}
        allowedRoles={ROUTE_ACCESS_MAP[ROUTES.WIFI_QR.path]}
        role={role}
      >
      </DashboardCard>

      <DashboardCard
        cardTitle={"IP Gather"}
        cardDescription={"Generate a list of IP Addresses in a country"}
        buttonText={"View"}
        onButtonClick={() => { navigate(ROUTES.IP_ZHUA.path) }}
        allowedRoles={ROUTE_ACCESS_MAP[ROUTES.IP_ZHUA.path]}
        role={role}
      >
      </DashboardCard>

      <DashboardCard
        cardTitle={"Speed Test"}
        cardDescription={"Create Speed Tests in your desired countries"}
        buttonText={"View"}
        onButtonClick={() => { navigate(ROUTES.SPEED_TEST.path) }}
        allowedRoles={ROUTE_ACCESS_MAP[ROUTES.SPEED_TEST.path]}
        role={role}
      >
      </DashboardCard>

      <DashboardCard
        cardTitle={"Network Devices Status"}
        buttonText={"View"}
        onButtonClick={() => { navigate(ROUTES.SYSTEM_HEALTH.path) }}
        allowedRoles={ROUTE_ACCESS_MAP[ROUTES.SYSTEM_HEALTH.path]}
        role={role}
      >
        {console.log(ROUTE_ACCESS_MAP[ROUTES.SYSTEM_HEALTH.path])}
        <Row justify="start" align="middle">
          <Col span={3}>CapSky: </Col>
          <Col span={2} style={{ textAlign: 'center' }}>
            {StatusCount(6) != 0
              ? <h1 style={{ color: 'var(--td-error-color-6)' }}>{errorCount}</h1>
              : <IconFont name='check-circle' size="2em" style={{ color: 'var(--td-success-color-6)' }} />
            }
          </Col>
        </Row>

        <Row justify="start" align="middle">
          <Col span={3}>CapSpring: </Col>
          <Col span={2} style={{ textAlign: 'center' }}>
            {StatusCount(7) != 0
              ? <h1 style={{ color: 'var(--td-error-color-6)' }}>{errorCount}</h1>
              : <IconFont name='check-circle' size="2em" style={{ color: 'var(--td-success-color-6)' }} />
            }
          </Col>
        </Row>
      </DashboardCard>

      {role ? null : <h2 style={{margin: "20px"}}>Your role has no service available</h2>}
      {role == "public" ? <h2 style={{margin: "20px"}}>Your role has no service available</h2> : null}

    </div>
  </Layout >
}


function DashboardCard(props) {

  return <Card

    className='card-style'
    title={props.cardTitle}
    actions={
      <a href={null} onClick={props.onButtonClick} style={{ cursor: 'pointer' }}>
        <Button>{props.buttonText ? props.buttonText : "View"}</Button>
      </a>
    }
    hoverShadow
    style={{
      display: props.allowedRoles && (props.allowedRoles.includes('public') || props.allowedRoles.includes('authenticated') || props.allowedRoles.includes(props.role)) ? "block" : "none",
      gridColumn: "span 1 / span 1",
      margin: '0.5rem',
      minHeight: '300px',
    }}
  >
    {props.children}
    {props.cardDescription ? props.cardDescription : ''}
  </Card>
}

export default Home
