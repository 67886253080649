import React, { useState, } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Input, Radio, Button, Divider, MessagePlugin, Space, Image } from 'tdesign-react';

import Layout from '../../layouts/Layout'


import { wifiQrGenerator } from '../../lib/qrCode';


const { FormItem } = Form;

export default ({ title, authMode, children }) => {

  const todayDt = new Date()
  const lastDay_init = todayDt.toISOString().substring(0, 10)

  const [qrCodeValue, setQrCodeValue] = useState("")
  const [form] = Form.useForm();

  const ssid = Form.useWatch('ssid', form)
  const password = Form.useWatch('password', form)
  const encryption = Form.useWatch('encryption', form)
  const hiddenSSID = Form.useWatch('hiddenSSID', form)

  const onSubmit = async (e) => {
    //createInformationRecord()

    if (!ssid || !password) {
      MessagePlugin("error", "SSID and Password cannot be none")
      return false
    }

    const result = await wifiQrGenerator({
      ssid,
      password,
      encryption,
      hiddenSSID,
      outputFormat: { type: 'image/png' }
    })

    setQrCodeValue(result)

  };

  return (<Layout title={title} authMode={authMode}>
    <Form form={form} onSubmit={onSubmit} labelWidth={100} style={{ padding: '15px 15%', }}>

      <FormItem label="SSID" name="ssid" labelAlign="left" rules={[{ required: true, message: 'Required', type: 'warning' }]}>
        <Input placeholder='SSID' style={{ width: "320px" }} />
      </FormItem>

      <FormItem label="Password" name="password" labelAlign="left" rules={[{ required: true, message: 'Required', type: 'warning' }]}>
        <Input placeholder='password' style={{ width: "320px" }} />
      </FormItem>

      <FormItem label="Encryption" name="encryption" labelAlign="left" initialData={"WPA2"} rules={[{ required: true, message: 'Required', type: 'warning' }]}>
        <Radio.Group style={{ background: "white" }}>
          <Radio.Button value="WEP">WEP</Radio.Button>
          <Radio.Button value="WPA">WPA</Radio.Button>
          <Radio.Button value="WPA2">WPA2</Radio.Button>
        </Radio.Group>
      </FormItem>
      <FormItem label="Hidden" name="hiddenSSID" labelAlign="left" initialData={"false"} rules={[{ required: true, message: 'Required', type: 'warning' }]}>
        <Radio.Group style={{ background: "white" }}>
          <Radio.Button value="false">False</Radio.Button>
          <Radio.Button value="true">True</Radio.Button>
        </Radio.Group>
      </FormItem>
      <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9" }}>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>QR Code</span>
      </Divider>
      <br />
      <br />
      <Space>
        {qrCodeValue ? <Image src={qrCodeValue} style={{ width: 200, height: 220 }}></Image> : ""}
      </Space>
      <br />
      <br />
      <FormItem style={{ marginLeft: 100 }}>
        <Space>
          <Button type="submit" theme="primary">
            Generate
          </Button>
        </Space>
      </FormItem>
    </Form>
  </Layout>);
}
