import { AES, HmacSHA256, enc } from 'crypto-js';


export default function SimpleCrypto(opts) {
  opts = opts || {};

  let SECRET = opts.secret

  return {
    encrypt: function(message) {
      return HmacSHA256(message, SECRET).toString();
    },

    secret: function() {
      return SECRET;
    }
  };
};


export function encryptSecret(msg, secret) {

  // encrypt secret
  const cipherText = AES.encrypt(msg, secret).toString();

  return {
    cipherText: cipherText,
    secret: secret
  }
}

export function decryptSecret(ciphertext, secret) {

  const bytes = AES.decrypt(ciphertext, secret);
  const plaintext = bytes.toString(enc.Utf8);
  return plaintext
}

