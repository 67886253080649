const coco = require('country-code-lookup')

// list of all available countries in the country-code-lookup library
const COUNTRY_CODE_LIST = coco.countries.map(_ => { return { label: _.country, value: _.iso3 } })

// returns a list all country codes
export const getCountryCodeList = () => {
  return COUNTRY_CODE_LIST
}

// based on strapi backend validation, only these regions are allowed for speedtest page
// to add more, you will also have to add on strapi side
const REGION_LIST = [
  'Great Middle East Asia',
  'Great South East Asia',
  'Great North East Asia',
  'US West',
  'US Mid',
  'US East',
  'US South',
  'EU East',
  'EU West',
  'EU North',
  'EU South',
  'EU',
  'UK',
  'Russiania',
  'Oceania',
  'Latin America',
  'South America'
]
// returns a list of all regions
export const getRegionList = () => {
  return REGION_LIST
}
// returns a list of 
export const getServerCountryList = () => {
  return coco.countries
}

// takes in a country name and returns an ISO3 country code
export const getCountryCodeByCountry = (country) => {
  // iso2 country code
  if (country.length === 2 || country.length === 3) {

    const cocoRes = coco.byIso(country)

    return cocoRes.iso3
  }

  // country
  return coco.byCountry(country).iso3
}

// takes in a country code in the form of ISO2 or ISO3 and returns a country
export const getCountryByCountryCode = (countryCode) => {
  if (countryCode.length > 3) {
    return countryCode
  }
  return countryCode == "PLE" ? coco.byIso("PSE")?.country : coco.byIso(countryCode)?.country
}

// takes in the ISO2 and returns the Country of it
export const getCountryByIso2 = (iso2) => {
  return coco.byIso(iso2).country
}

// takes in the ISO2 and returns the Country of it
export const getCountryByIso3 = (iso3) => {
  return coco.byIso(iso3).country
}

// takes in the ISO2 and returns the ISO3 for it
export const getIso3ByIso2 = (iso2) => {
  if(coco.byIso(iso2)) {
    return coco.byIso(iso2).iso3 
  }
  
  return
}

// takes in a country in an unknown form and tries to
// search for it in the country-code-lookup library
// if can't find results after exhausting all search, it will return
// the original string that was passed in
export const getCountryByAnyForm = (unknownCountry) => {
  let result = null;
  const lcUnknownCountry = unknownCountry.toLowerCase()
  const ucUnknownCountry = unknownCountry.toUpperCase()

  // try to get by iso
  try {
    result = coco.byIso(ucUnknownCountry)
  }
  catch (e) {
    result = null
  }

  if (result != null) {
    return result.country
  }


  // try to get by fips
  try {
    result = coco.byFips(ucUnknownCountry)
  }
  catch (e) {
    result = null
  }

  if (result != null) {
    return result.country
  }

  // try to get by internet code
  try {
    result = coco.byInternet(ucUnknownCountry)
  }
  catch (e) {
    result = null
  }

  if (result != null) {
    return result.country
  }

  // try to get by its country name
  try {
    result = coco.byCountry(lcUnknownCountry)
  }
  catch (e) {
    result = null
  }

  if (result != null) {
    return result.country
  }

  // if can't find in any matching record, jsut return original string
  return unknownCountry

}

