
// gets the role of the current user by retrieving it from local storage


// if user info isn't available, return an empty string
export const getCurrentUserRole = () => {
  const userInfoStr = localStorage.getItem("userInfo") || "{}"
  const userInfo = JSON.parse(userInfoStr)
  return userInfo['role'] ? userInfo['role']['type'] : ""
}

