import React, { useEffect, useState } from 'react';
import { Layout, Menu, Skeleton, Tag, Watermark } from 'tdesign-react';
import {
  LogoutIcon,
  NotificationFilledIcon,
  HomeIcon,
} from 'tdesign-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { ROLE_ACCESS_MAP, ROUTES } from '../lib/router.js'

import { AUTH_MODE_BASIC, AUTH_MODE_OTP_EMAIL, AUTH_MODE_OTP_EMAIL_EXT } from '../metadata/authMode';
import { verifyTencentEmail } from '../lib/validation'

import './Layout.css'

const { Header, Content, Footer } = Layout;
const { HeadMenu, MenuItem } = Menu;

/**
 * this layout is in a high view to control the access by role and otp
 */

export default ({ title, authMode, children, }) => {

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(true) // assume that the user is authenticated first
  const [isAuthorized, setIsAuthorized] = useState(true) // assume the users is authorised first
  const [username, setUsername] = useState('')

  const todayDt = new Date()
  const todayDtFormatted = todayDt.toISOString().substring(0, 10)

  // cleanup function for logout
  const logOutCleanUp = (path, target) => {
    const originate = encodeURIComponent(location.href)

    localStorage.removeItem("userInfo")
    navigate(`${path}?${target ? `target=${target}` : ''}originate=${originate}`)
  }


  useEffect(() => {
    // check if userInfo exist in local storage
    const lsUserInfo = localStorage.getItem("userInfo")
    if (!lsUserInfo) {
      setIsAuthenticated(false)
      return
    }

    // check if user info is valid
    const userInfo = JSON.parse(lsUserInfo)

    // if the userInfo is invalid or the role is not in the valid roles, log the user out
    const isValidUser = (
      userInfo // userInfo is valid
      && Object.keys(userInfo).length > 0 // userInfo has at keys
      && userInfo['username'] // username exists
      && userInfo['role'] // role exists
      && userInfo['role'].type // role type exists
      && Object.keys(ROLE_ACCESS_MAP).includes(userInfo['role'].type)) // is a valid role

    // invalid user
    if (!isValidUser) {
      setIsAuthenticated(isValidUser)
      return
    }

    setUsername(userInfo['username'])

    const isEmailAuth = authMode == AUTH_MODE_OTP_EMAIL || authMode == AUTH_MODE_OTP_EMAIL_EXT
    const { attributes } = userInfo

    if (isEmailAuth) {
      // if the user is using OTP Auth, check:
      const hasValidEmailAuth = (
        attributes // attributes exists
        && attributes['expiryAt'] // expiryAt exists
        && attributes['email'] // email exists
        && new Date(attributes['expiryAt']) > todayDt // not expired yet
        && verifyTencentEmail(attributes['email']) // valid tencent email
      )
      if (!hasValidEmailAuth) {
        setIsAuthenticated(hasValidEmailAuth)
        return
      }
    }

    // user is authorized to view page if one of the following is met:
    // 1. Page is public - accessible by everyone
    // 2. Page requires authentication - accessible for any authenticated users
    // 2. Page has been granted to the role the user has
    const isAuthorizedToAccess = ROLE_ACCESS_MAP['public'].includes(location.pathname) || ROLE_ACCESS_MAP['public'].includes(location.pathname) || ROLE_ACCESS_MAP[userInfo['role'].type].includes(location.pathname)
    if (!isAuthorizedToAccess) {
      setIsAuthorized(isAuthorizedToAccess)
      return
    }

    setIsLoading(false)
  }, [])

  // side effect for authentication
  useEffect(() => {
    // if the user is authenticated, don't perform cleanup
    if (isAuthenticated) {
      return
    }

    let cleanUpArgs = []
    // setup the cleanUpArgs
    if (authMode == AUTH_MODE_BASIC) {
      cleanUpArgs = [ROUTES.LOGIN.path, null]
    }
    else if (authMode == AUTH_MODE_OTP_EMAIL) {
      cleanUpArgs = [ROUTES.OTP_LOGIN.path, 'internal']
    }
    else if (authMode == AUTH_MODE_OTP_EMAIL_EXT) {
      cleanUpArgs = [ROUTES.OTP_LOGIN.path, 'external']
    }

    setIsLoading(false)
    logOutCleanUp(...cleanUpArgs)

  }, [isAuthenticated])

  // check if its still loading
  return <Layout >
    <Header style={{ padding: '0 15px' }}>
      <HeadMenu
        value="item1"
        logo={<Link to={ROUTES.HOME.path}><img width="136" src="https://www.tencent.com/img/index/menu_logo_hover.png" alt="logo" /></Link>}
        operations={
          <div className="t-menu__operations screen-title">
            <NotificationFilledIcon className="t-menu__operations-icon" style={{ display: "none" }} />
            Today: &nbsp;<Tag style={{ marginRight: "4px" }}>{todayDtFormatted}</Tag>|
            Welcome &nbsp; <Tag style={{ marginRight: "4px" }}> {username}</Tag> |
            <HomeIcon className="t-menu__operations-icon" onClick={() => { navigate(ROUTES.HOME.path) }} style={{ display: "none" }} />
            <LogoutIcon className="t-menu__operations-icon" onClick={() => {
              setIsAuthenticated(false)
              setIsAuthorized(false)
            }} style={{ display: username ? "" : "none" }} />
          </div>
        }
      >
        <MenuItem className='screen-title' value="item1" onClick={() => { location.reload() }}>{title}</MenuItem>
      </HeadMenu>
    </Header>
    {
      isAuthorized
        ?
        <>
          <Skeleton theme={'article'} delay={5000} animation='gradient' style={{ backgroundColor: 'white', display: isLoading ? 'block' : 'none', padding: "100px" }}></Skeleton>
          <Content className='content' style={{ display: isLoading ? 'none' : 'block' }} >
            {children}
          </Content>
        </>
        :
        <Watermark watermarkContent={{ text: 'You are not authorized to view this content.' }} y={300} x={500}>
          <div style={{ height: '100vh' }}></div>
        </Watermark>
    }
    <Footer className='footer'>Copyright @ Tencent OIT APAC. All Rights Reserved</Footer>
  </Layout>

}
