import axiosInstance from './axios'
import qs from 'qs'

export const getBoardingRecords = async () => {
  let result = []
  const query = qs.stringify({
    populate: "*",
    pagination: {'limit':1000},
    sort: ['id:desc'],
  });

  const resp = await axiosInstance.get(`/boarding-records?${query}`)

  if(resp.status == 200) {

    if(resp.data && resp.data['data'].length > 0) {
      result = resp['data']['data'].map(_ => {
        let obj = _.attributes
        obj['id'] = _.id

        return obj
      })
    }
  }

  return result
}

export const getRecordsByTenId = async (tencentId, assetType) => {
  const query = qs.stringify({
    filters: {
      tencentId: {
        $eq: tencentId
      }
    },
    populate: "*"
  });

  const resp = await axiosInstance.get(`/boarding-records?${query}`)

  if(resp.status == 200) {

    if(resp.data && resp.data['data'].length > 0) {
      return resp.data['data']
    }
  }

  return []

}

export const updateRecord = async (id, data) => {

  const resp = await axiosInstance.put(`/boarding-records/${id}`, data)

  return resp
}

export const createRecord = async (data) => {

  const resp = await axiosInstance.post("/boarding-records", data)

  return resp
}

export const deleteRecord = async (id) => {
  const resp = await axiosInstance.delete(`/boarding-records/${id}`)

  return resp
}