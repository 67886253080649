import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Radio, Checkbox, Button, Divider, MessagePlugin, DatePicker, Tooltip, Space } from 'tdesign-react';

import { RollbackIcon } from 'tdesign-icons-react';
import Layout from '../../layouts/Layout'

import SignatureCanvas from 'react-signature-canvas'
import './OffboardingForm.css'

import {
  getRecordsByTenId,
  updateRecord
} from '../../services/boardrecords';
import { departments } from '../../metadata/general'
import { ROUTES } from '../../lib/router';


const { FormItem } = Form;

const deptOptions = departments

export default ({ title, authMode, children }) => {
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(window.location.search);
  const tencentId_init = searchParams.get('tencentId')

  const [record, setRecord] = useState({})

  useEffect(() => {
    let fetched = false
    const fetchData = async () => {
      const resp = await getRecordsByTenId(tencentId_init) //data.filter(e => e['tencentId'] == tencentId)

      if (!fetched) setRecord(resp[0] || {})
    }

    fetchData()

    return () => { fetched = true; }

  }, [tencentId_init])

  const [form] = Form.useForm();
  const id = Form.useWatch('id', form)
  const tencentId = Form.useWatch('tencentId', form)
  const lastday = Form.useWatch('lastday', form)
  const offAcknowledged = Form.useWatch('offAck', form)
  const otherAssets = Form.useWatch('others', form)
  const staffPass = Form.useWatch('staffPass', form)
  const pedestalKey = Form.useWatch('pedestalKey', form)
  const accessCard = Form.useWatch('accessCard', form)

  let sigPadUser = {}
  let sigPadIT = {}
  let sigPadHR = {}
  let sigPadAdmin = {}
  const clearSigUser = () => {
    sigPadUser.clear()
  }
  const clearSigIT = () => {
    sigPadIT.clear()
  }
  const clearSigHR = () => {
    sigPadHR.clear()
  }
  const clearSigAdmin = () => {
    sigPadAdmin.clear()
  }

  const onSubmit = async (e) => {
    //if(!sigPad.isEmpty())

    let assets = record['attributes']['assets']
    let hasOthers = false
    assets.forEach(asset => {
      if (asset['type'] == "Others") {
        hasOthers = true
      }

      asset['returned'] = true
    })
    if (!hasOthers && otherAssets) {
      assets.push({ serialNumber: otherAssets, type: "Others" })
    }

    let offSignatures = record['attributes']['offSignatures'] || {}
    if (!offSignatures["UserSignature"]) {
      offSignatures["UserSignature"] = sigPadUser.isEmpty() ? "" : sigPadUser.toDataURL()
    }
    if (!offSignatures["ITSignature"]) {
      offSignatures["ITSignature"] = sigPadIT.isEmpty() ? "" : sigPadIT.toDataURL()
    }
    if (!offSignatures["AdminSignature"]) {
      offSignatures["AdminSignature"] = sigPadAdmin.isEmpty() ? "" : sigPadAdmin.toDataURL()
    }
    if (!offSignatures["HRSignature"]) {
      offSignatures["HRSignature"] = sigPadHR.isEmpty() ? "" : sigPadHR.toDataURL()
    }

    const payload = {
      data: {
        offboardingDate: lastday,
        assets,
        staffPass: !staffPass,
        pedestalKey: !pedestalKey,
        accessCard: !accessCard,
        offAcknowledged,
        offSignatures,
      }
    }

    const resp = await updateRecord(id, payload)

    if (resp.status == 200) {
      MessagePlugin.info("Done");
      navigate(ROUTES.HOME.path)
    } else {
      MessagePlugin.error("Fail to save");
    }

  };

  let content = (<Form form={form}>
    <div style={{ textAlign: 'center' }}>
      <p>No record found</p><br />
      <Button theme="danger" variant="base" onClick={() => { navigate(-1) }}>
        <RollbackIcon />Go back to previous page
      </Button>
    </div>
  </Form>)

  if (Object.keys(record).length > 0) {
    const todayDt = new Date()
    const { id, attributes } = record

    const lastDay_init = attributes['offboardingDate'] || todayDt.toISOString().substring(0, 10)
    const dept_init = attributes['department'] || ""
    const offSignatures = attributes['offSignatures'] || {}

    let hasOthers = false
    const assetsDOM = attributes['assets'].map((asset, idx) => {
      if (asset['type'] == "Others") {
        hasOthers = true
      }
      return <Space direction='horizontal' style={{ display: "flex", margin: "15px 0" }}>
        <FormItem style={{ width: "80px" }}>
          <Button variant='outline' theme="warning">{asset['type']}:</Button>
        </FormItem>
        <FormItem key={`asset-model-${idx}`} label={`Model`} labelAlign='left' name={`asset-model-${idx}`} initialData={asset['model']} style={{ minWidth: "320px" }}>
          <Input readonly />
        </FormItem>
        <FormItem key={`asset-sn${idx}`} label={`SN`} labelAlign='left' name={`asset-sn${idx}`} initialData={asset['serialNumber']} style={{ minWidth: "480px" }}>
          <Input readonly />
        </FormItem>
      </Space>
    })

    if (!hasOthers) {
      assetsDOM.push(<FormItem key={`asset-1000`} label={`Others`} labelAlign='left' name={`others`} style={{ width: '50%' }}>
        <Input placeholder='' />
      </FormItem>)
    }

    content = <Form form={form} onSubmit={onSubmit} colon labelWidth={100} style={{ padding: '15px 15%', }}>
      <FormItem label="ID" name="id" initialData={id} style={{ width: '50%', display: 'none' }}>
        <Input readonly placeholder='' />
      </FormItem>
      <FormItem label="Tencent ID" labelAlign='left' name="tencentId" initialData={tencentId_init} style={{ width: '50%' }}>
        <Input readonly placeholder='' />
      </FormItem>

      <FormItem label="Last Day" labelAlign='left' name="lastday" initialData={lastDay_init}>
        <DatePicker mode="date" placeholder='' />
      </FormItem>

      <FormItem label="Department" labelAlign='left' name="dept" initialData={dept_init}>
        <Radio.Group options={deptOptions} ></Radio.Group>
      </FormItem>
      <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9" }}>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>IT Endorsement(Device returned)</span>
      </Divider>
      {assetsDOM}
      <FormItem name="offAck" initialData={attributes['offAcknowledged']}>
        <Checkbox /> &nbsp;&nbsp; I acknowledge that I have returned all the above checked devices and accessories!
      </FormItem>
      <Space direction='horizontal'>
        <div className='signature-panel'>
          <p>User Signature</p>
          {offSignatures['UserSignature'] ? <img src={offSignatures['UserSignature']}></img> : <>
            <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadUser = ref }}
              canvasProps={{ width: 210, height: 120, className: 'sigCanvas' }} />
            <div>
              <Button size='small' variant="outline" theme="dashed" onClick={clearSigUser}>Clear</Button>
            </div>
          </>
          }
        </div>
        <div className='signature-panel'>
          <p>IT Signature</p>
          {offSignatures['ITSignature'] ? <img src={offSignatures['ITSignature']}></img> : <>
            <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadIT = ref }}
              canvasProps={{ width: 210, height: 120, className: 'sigCanvas' }} />
            <div>
              <Button size='small' variant="outline" theme="dashed" onClick={clearSigIT}>Clear</Button>
            </div></>
          }
        </div>
      </Space>

      <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9" }}>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>Admin Endorsement(Staff Pass and Access Card)</span>
      </Divider>
      <Space direction='horizontal'>
        <FormItem label="" name="staffPass" initialData={!attributes['staffPass']}>
          <Checkbox /> &nbsp;&nbsp;Staff Pass
        </FormItem>
        <FormItem label="" name="accessCard" initialData={!attributes['accessCard']}>
          <Checkbox />&nbsp;&nbsp;Access Card
        </FormItem>
        <FormItem label="" name="pedestalKey" initialData={attributes['pedestalKey']}>
          <Checkbox />&nbsp;&nbsp;Pedestal Keys
        </FormItem>
      </Space>


      <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9" }}>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>Admin Signatures</span>
      </Divider>

      <Space direction="horizontal">
        <div className='signature-panel'>
          <p>Admin Signature</p>
          {offSignatures['AdminSignature'] ? <img src={offSignatures['AdminSignature']}></img> : <>
            <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadAdmin = ref }}
              canvasProps={{ width: 210, height: 120, className: 'sigCanvas' }} />
            <div>
              <Button size='small' variant="outline" theme="dashed" onClick={clearSigAdmin}>Clear</Button>
            </div>
          </>
          }
        </div>
        <div className='signature-panel' style={{ display: "none" }}>
          <p>HR Signature</p>
          {offSignatures['HRSignature'] ? <img src={offSignatures['HRSignature']}></img> : <>
            <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadHR = ref }}
              canvasProps={{ width: 210, height: 120, className: 'sigCanvas' }} />
            <div>
              <Button size='small' variant="outline" theme="dashed" onClick={clearSigHR}>Clear</Button>
            </div>
          </>
          }
        </div>

      </Space>

      <Divider></Divider>
      <FormItem style={{ marginLeft: 100 }}>
        <Space>
          <Button type="submit" theme="primary">
            Submit
          </Button>
        </Space>
      </FormItem>
    </Form>
  }

  return (
    <Layout title={title} authMode={authMode}>
      {content}
    </Layout>);
}
