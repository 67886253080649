export const departments = [
    { label: 'WXG', value: 'WXG' },
    { label: 'TEG', value: 'TEG' },
    { label: 'CDG', value: 'CDG' },
    { label: 'CSIG', value: 'CSIG' },
    { label: 'IEGG', value: 'IEGG' },
    { label: 'S1', value: 'S1' },
    { label: 'S2', value: 'S2' },
    { label: 'S3', value: 'S3' },
  ];
