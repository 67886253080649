import React, { useEffect } from 'react';
import { MessagePlugin, Divider, Layout, Loading } from 'tdesign-react';
import { useNavigate } from "react-router-dom";

import { loginViaSSO } from '../../services/auth'
import { ROUTES } from '../../lib/router';


export default () => {

  const searchParams = new URLSearchParams(window.location.hash)
  const idToken = searchParams.get('#id_token')

  useEffect(async () => {
    if (idToken && idToken.split(".").length > 1) {
      const authString = atob(idToken.split(".")[1])
      try {
        const authJson = JSON.parse(authString)
        const userId = authJson['sub']

        const loginResult = await loginViaSSO(userId)
        if (loginResult) {
          MessagePlugin.info('Login Successfully');

          localStorage.setItem("userInfo", JSON.stringify(loginResult))

          location.href = ROUTES.HOME.path
        } else {
          MessagePlugin.error('user name or password is wrong');
        }

      } catch (err) {
        console.log(err)
      }
    }
  }, [idToken])



  return (
    <Layout style={{ height: '100vh' }}>
      <div className='login-form'>
        <Divider><span style={{ fontWeight: 700, fontSize: '1.2rem' }}><Loading /> Authenticating...</span></Divider>

      </div>
    </Layout>
  );
}
