import { GetObjectCommand, S3Client, ListObjectsCommand } from "@aws-sdk/client-s3";
import {
  getSignedUrl,
} from "@aws-sdk/s3-request-presigner";
import config from "../config";
import { decryptSecret } from "./simpleCrypto";
import axiosInstance, { STRAPI_URL } from "../services/axios";

let ENCRYPTION_TOKEN;

const retrieveEncryptionToken = async () => {
  // id: 1 refers to the OneOitApac-Frontend token
  const resp = await axiosInstance.get(STRAPI_URL + "crypto-services/1")

  if (resp['status'] === 200) {
    const token = resp['data']['data']['attributes']['token']
    return token
  }

  return null
}

export const getKeys = async () => {
  // check if the token is empty
  // if its empty, retrieve it
  ENCRYPTION_TOKEN = ENCRYPTION_TOKEN ?? await retrieveEncryptionToken()

  const secretAccessKey = decryptSecret(config.s3SecretAccessKey, ENCRYPTION_TOKEN)
  const accessKeyId = decryptSecret(config.s3AccessKeyId, ENCRYPTION_TOKEN)

  return {
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey
  }
}

const region = 'ap-southeast-1'
const bucket = 'oit-ip-store'

export const createPresignedUrlWithClient = async (key) => {
  const credentials = await getKeys()
  const client = new S3Client({ region: region, credentials: credentials });
  const command = new GetObjectCommand({ Bucket: bucket, Key: key });
  return getSignedUrl(client, command, { expiresIn: 3600 });
};

export const listPrefixObjects = async (prefix) => {
  const credentials = await getKeys()
  const client = new S3Client({ region: region, credentials: credentials });
  const command = new ListObjectsCommand({
    Bucket: bucket,
    Prefix: prefix
  });
  const result = await client.send(command)
  return result
}


