import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Tabs } from 'tdesign-react';
import 'tdesign-react/es/style/index.css';
import Layout from '../../layouts/Layout'

import { DisplayNetworkStatus } from './NetworkStatusSummary';

const { TabPanel } = Tabs;

export default ({ title, authMode, children }) => {


  return (
    <Layout title={title} authMode={authMode}>
      <div>
        <Tabs placement={'left'} defaultValue={'1'} size={'large'} theme={'normal'} disabled={false}>
          <TabPanel value={'1'} label="CapSpring">
            <DisplayNetworkStatus id={7}></DisplayNetworkStatus>
          </TabPanel>
          <TabPanel value={'2'} label="CapSky">
            <DisplayNetworkStatus id={6}></DisplayNetworkStatus>
          </TabPanel>
        </Tabs>
      </div>
    </Layout>
  );
}
