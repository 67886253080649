import React, { useState, useEffect } from 'react';
import { Loading, Card, Table, Tag, Row, Col } from 'tdesign-react';
import { IconFont } from 'tdesign-icons-react';
import 'tdesign-react/es/style/index.css';

import { getStatusData } from '../../services/statusdata';

export const DisplayNetworkStatus = ({ title, authMode, children, id }) => {


  //   const todayDt = new Date()
  //   const dt_init = todayDt.toLocaleDateString().substring(0, 10)

  const [records, setRecords] = useState([])
  //   const [visibleWarning, setVisibleWarning] = useState(false);
  //   const [warningMessage, setWarningMessage] = useState("");

  const [stripe, setStripe] = useState(false);
  const [bordered, setBordered] = useState(false);
  const [hover, setHover] = useState(false);
  const [tableLayout, setTableLayout] = useState(false);
  const [size, setSize] = useState('medium');
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {

    let fetched = false
    const fetchData = async () => {
      const resp = await getStatusData(id)

      if (!fetched) setRecords(resp)
    }

    fetchData()

    return () => { fetched = true; }

  }, [])

  //   const onDetailBtnClick = (message) => {
  //     setWarningMessage(message)
  //     setVisibleWarning(true);
  //   }

  //   const onCloseWraing = () => {
  //     setVisibleWarning(false);
  //   };

  const statusMap = (statusData) => {
    switch (statusData) {
      case 'success':
        return { label: 'Normal', theme: 'success', icon: <IconFont name='check-circle-filled' /> }
      case 'error':
        return { label: 'Error', theme: 'danger', icon: <IconFont name='close-circle-filled' /> }
      case 'warning':
        return { label: 'Unknown', theme: 'warning', icon: <IconFont name='error-circle-filled' /> }
    }
  }

  var formData = [];

  let content = <Loading />;
  if (records.length > 0) {
    formData = records.map(record => {
      return {
        index: record['id'],
        device: record['name'],
        ipAddress: record['ip'],
        status: record['status'],
      }
    })
    content =
      <Table
        data={formData}
        columns={[
          { colKey: 'device', title: 'Device Name' },
          { colKey: 'ipAddress', title: 'IP Address' },
          {
            colKey: 'status',
            title: 'Status',
            cell: ({ row }) => (
              <Tag
                shape="round"
                theme={statusMap(row.status).theme}
                variant="light-outline"
                icon={statusMap(row.status).icon}
                style={{ width: 90 }}
              >
                {statusMap(row.status).label}
              </Tag>
            ),
          }
        ]}
        rowKey='index'
        verticalAlign="center"
        size={size}
        bordered={bordered}
        hover={hover}
        stripe={stripe}
        showHeader={showHeader}
        tableLayout={tableLayout ? 'auto' : 'fixed'}
        rowClassName={({ rowIndex }) => `${rowIndex}-class`}
        cellEmptyContent={'-'}

        onRowClick={({ row, index, e }) => {
          console.log('onRowClick', { row, index, e });
        }}
      />
  }

  return (
    <>
      {console.log('run once')}
      {console.log(records.length)}
      <GetSummary id={id}></GetSummary>
      <Row><Card
        shadow
        size='medium'
        style={{ margin: 10, width: '100%' }}
      >
        {content}
      </Card></Row>
    </>
  );
}


export function GetSummary({ id }) {
  StatusCount(id);
  return (
    <Row justify='align left'>
      <SummaryCard
        description='Total'
        number={totalCount}
        icon='root-list'
      >
      </SummaryCard>
      <SummaryCard
        description='Normal'
        number={normalCount}
        icon='check-circle'
        color='var(--td-success-color-6)'
      >
      </SummaryCard>
      <SummaryCard
        description='Errors'
        number={errorCount}
        icon='close-circle'
        color='var(--td-error-color-6)'
      >
      </SummaryCard>
      <SummaryCard
        description='Unknown'
        number={warningCount}
        icon='error-circle'
        color='var(--td-warning-color-6)'
      >
      </SummaryCard>
    </Row>
  );
}

function SummaryCard({ description, number, icon, color }) {
  return (
    <Card
      shadow
      size='medium'
      style={{ margin: 10, width: '20%', minWidth: '200px' }}
    >
      <Row justify='space-around' align='bottom'>
        <Col span={4} style={{ textAlign: 'left' }}>
          <Row><IconFont name={icon} size="2em" style={{ color: color }} /></Row>
          <Row><p>{description}</p></Row>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}><h1 style={{ fontSize: 60, color: color }}>{number}</h1></Col>
      </Row>
    </Card>
  );
}

export var totalCount, normalCount = 0, errorCount = 0, warningCount = 0;


export const StatusCount = (id) => {
  const [records, setRecords] = useState([])

  useEffect(() => {

    let fetched = false
    const fetchData = async () => {
      const resp = await getStatusData(id)

      if (!fetched) setRecords(resp)
    }

    fetchData()

    return () => { fetched = true; }

  }, [])

  if (records.length > 0) {
    totalCount = records.length;
    normalCount = 0;
    errorCount = 0;
    warningCount = 0;

    records.map(record => {

      switch (record.status) {
        case 'success':
          normalCount++;
          break;
        case 'error':
          errorCount++;
          break;
        case 'warning':
          warningCount++;
          break;
      }
    })

  }

  return errorCount;
}
