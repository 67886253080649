import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Radio, Checkbox, Button, Divider, MessagePlugin, DatePicker, Tooltip, Space, Loading } from 'tdesign-react';
import { AddIcon, RemoveIcon, BarcodeIcon } from 'tdesign-icons-react';
import Layout from '../../layouts/Layout'
import { Popup } from 'tdesign-mobile-react';

import SignatureCanvas from 'react-signature-canvas'
import './OnboardingForm.css'

import { QrReader } from 'react-qr-reader';

import {
  getRecordsByTenId,
  createRecord
} from '../../services/boardrecords';
import { departments } from '../../metadata/general'
import RadioGroup from 'tdesign-react/es/radio/RadioGroup';
import { ROUTES } from '../../lib/router';

const ASSET_TYPES = {
  LAPTOP: "Laptop",
  DESKTOP: "Desktop",
  MONITOR: "Monitor",
  ACCESSORIES: "Accessories",
  OTHERS: "Others"
}

const { FormItem } = Form;

const deptOptions = departments

export default ({ title, authMode, children }) => {
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(window.location.search);
  const tencentId_init = searchParams.get('tencentId')

  const todayDt = new Date()
  const lastDay_init = todayDt.toISOString().substring(0, 10)

  const [form] = Form.useForm();
  const [computerGroup, setComputerGroup] = useState([])
  const [monitorGroup, setMonitorGroup] = useState([])

  //for qr scanner
  const [visible, setPopupVisible] = useState(false)
  const handleqrVisibleChange = (visible) => {
    setPopupVisible(visible)
  }
  const [showQRScanner, setShowQRScanner] = useState(false)
  const [inputName, setInputName] = useState('')

  const id = Form.useWatch('id', form)
  const tencentId = Form.useWatch('tencentId', form)
  const boardingday = Form.useWatch('boardingday', form)
  const dept = Form.useWatch('dept', form)
  const otherAssets = Form.useWatch('others', form)
  const onAcknowledged = Form.useWatch('onAck', form)
  const staffPass = Form.useWatch('staffPass', form)
  const pedestalKey = Form.useWatch('pedestalKey', form)
  const accessCard = Form.useWatch('accessCard', form)

  const loginInfo = localStorage.getItem("userInfo")
  const userInfo = JSON.parse(loginInfo)
  const oitUser = userInfo['username']

  //check if user onboarded
  useEffect(() => {
    let fetched = false
    const fetchData = async () => {
      const resp = await getRecordsByTenId(tencentId_init) //data.filter(e => e['tencentId'] == tencentId)

      if (!fetched && resp.length > 0) {
        MessagePlugin.error("User ID exists, please onboard another one");
        navigate(-1)
      }
    }

    fetchData()

    return () => { fetched = true; }

  }, [tencentId_init])

  let def = {}
  let sigPadUser = {}
  let sigPadIT = {}
  let sigPadHR = {}
  let sigPadAdmin = {}
  const clearSigUser = () => {
    sigPadUser.clear()
  }
  const clearSigIT = () => {
    sigPadIT.clear()
  }
  const clearSigHR = () => {
    sigPadHR.clear()
  }
  const clearSigAdmin = () => {
    sigPadAdmin.clear()
  }

  const onSubmit = async (e) => {
    //check required
    if (sigPadUser.isEmpty()) {
      MessagePlugin.error("Sigature is required, please draw one");
      return
    }
    if (!onAcknowledged) {
      MessagePlugin.error("Please tick acknowledgement checkbox");
      return
    }
    if (!dept) {
      MessagePlugin.error("Please choose your department");
      return
    }

    const assets = []
    for (let i = 0; i < computerGroup.length; i++) {
      if (form.getFieldValue(`computerType-sn${i}`)) {
        assets.push({
          type: form.getFieldValue(`computerType-${i}`).split("-")[0],
          model: form.getFieldValue(`computerType-${i}`).split("-")[1],
          serialNumber: form.getFieldValue(`computerType-sn${i}`)
        })
      }
    }

    for (let i = 0; i < monitorGroup.length; i++) {
      if (form.getFieldValue(`monitor-model-${i}`)) {
        assets.push({
          type: "Monitor",
          model: form.getFieldValue(`monitor-model-${i}`),
          serialNumber: form.getFieldValue(`monitor-sn${i}`)
        })
      }
    }

    if (otherAssets) {
      assets.push({
        type: "Others",
        model: otherAssets
      })
    }

    const payload = {
      data: {
        tencentId: tencentId,
        onboardingDate: boardingday,
        department: dept,
        onAcknowledged,
        assets,
        staffPass: true,
        pedestalKey: null,
        accessCard: true,
        onSignatures: {
          UserSignature: sigPadUser.isEmpty() ? "" : sigPadUser.toDataURL()
        },
        oitUser,
      }
    }

    const resp = await createRecord(payload)

    if (resp.status == 200) {
      MessagePlugin.info("Done");
      navigate(ROUTES.HOME.path)
    } else {
      MessagePlugin.error("Fail to save");
    }

  };

  const barcodeScannerClick = (assetType, index) => {
    setPopupVisible(true);
    setShowQRScanner(true);
    if (assetType.includes("computer")) {
      setInputName(`computerTypeSN${index}`)
    } else {
      setInputName(`monitorSN${index}`)
    }
  }

  const handleScan = data => {
    if (data) {
      setShowQRScanner(false);
      setPopupVisible(false);

      form.setFieldsValue({
        [inputName]: data.text,
      })
    }
  };

  const handleError = error => {
    console.error(error);
  };

  if (computerGroup.length == 0) {
    let newCpGroup = []

    newCpGroup.push((<>
      <FormItem key={`computertype-0`} label="Type" labelAlign="left" name="computerType-0" initialData={"Laptop-MacBookPro"}>
        &nbsp;<RadioGroup defaultValue="Laptop-MacBookPro" style={{ backgroundColor: "white" }}>
          <Radio.Button key="computer-1" value="Laptop-MacBookPro">MacBook Pro</Radio.Button>
          <Radio.Button key="computer-2" value="Laptop-LenovoX1">Lenovo X1</Radio.Button>
          <Radio.Button key="computer-3" value="Desktop-PC">PC</Radio.Button>
          <Radio.Button key="computer-4" value="Others">Others</Radio.Button>
        </RadioGroup>
      </FormItem>
      <FormItem label={`SN`} labelAlign="left" name={`computerTypeSN0`} style={{ width: '50%' }} >
        <Input placeholder='serial number' /> &nbsp;
        <Button type="button" onClick={() => barcodeScannerClick('computer', 0)}><BarcodeIcon /></Button>
      </FormItem>

    </>))
    setComputerGroup(newCpGroup)
  }

  if (monitorGroup.length == 0) {
    let newMnGroup = []

    newMnGroup.push((<>
      <FormItem label="Monitor Size" labelAlign="left" name="monitor-0" initialData={"Monitor-27"}>
        <RadioGroup defaultValue="Monitor-27">
          <Radio.Button key="monitor-1" value="Monitor-27">27 inch</Radio.Button>
          <Radio.Button key="monitor-2" value="Monitor-34">34 inch</Radio.Button>
        </RadioGroup>
      </FormItem>
      <FormItem label={`Model`} labelAlign="left" name={`monitor-model-0`} style={{ width: '50%' }}>
        <Input placeholder='model name' />
      </FormItem>
      <FormItem label={`SN`} labelAlign="left" name={`monitorSN0`} style={{ width: '50%' }} >
        <Input placeholder='serial number' /> &nbsp;
        <Button type="button" onClick={() => barcodeScannerClick('monitor', 0)}><BarcodeIcon /></Button>
      </FormItem>

    </>))
    setMonitorGroup(newMnGroup)
  }

  const onComputerAdd = () => {
    let newCpGroup = [...computerGroup]

    const countId = newCpGroup.length
    newCpGroup.push((<>
      <FormItem key={`computertype-${countId}`} label="Type" name={`computerType-${countId}`} initialData={"Laptop-MacBookPro"}>
        &nbsp;<RadioGroup defaultValue="Laptop-MacBookPro" style={{ backgroundColor: "white" }}>
          <Radio.Button key="computer-1" value="Laptop-MacBookPro">MacBook Pro</Radio.Button>
          <Radio.Button key="computer-2" value="Laptop-LenovoX1">Lenovo X1</Radio.Button>
          <Radio.Button key="computer-3" value="Desktop-PC">PC</Radio.Button>
          <Radio.Button key="computer-4" value="Others">Others</Radio.Button>
        </RadioGroup>
      </FormItem>
      <FormItem label={`SN`} name={`computerTypeSN${countId}`} style={{ width: '50%' }}>
        <Input placeholder='serial number' />&nbsp;
        <Button size="small" type="button" variant="base" onClick={() => barcodeScannerClick('computer', countId)}><BarcodeIcon /></Button>
      </FormItem>

    </>))

    setComputerGroup(newCpGroup)
  }

  const onMonitorAdd = () => {
    let newMnGroup = [...monitorGroup]

    const countId = newMnGroup.length
    newMnGroup.push((<>
      <FormItem label="Monitor Size" name={`monitor-${countId}`} initialData={"Monitor-27"}>
        <RadioGroup defaultValue="Monitor-27">
          <Radio.Button key="monitor-1" value="Monitor-27">27 inch</Radio.Button>
          <Radio.Button key="monitor-2" value="Monitor-34">34 inch</Radio.Button>
        </RadioGroup>
      </FormItem>
      <FormItem label={`Model`} name={`monitor-model-${countId}`} style={{ width: '50%' }}>
        <Input placeholder='model name' />
      </FormItem>
      <FormItem label={`SN`} name={`monitorSN${countId}`} style={{ width: '50%' }}>
        <Input placeholder='serial number' /> &nbsp;
        <Button size="small" type="button" variant="base" onClick={() => barcodeScannerClick('monitor', countId)}><BarcodeIcon /></Button>
      </FormItem>
    </>))

    setMonitorGroup(newMnGroup)
  }

  const onComputerRemove = () => {
    let newCpGroup = [...computerGroup]
    newCpGroup = newCpGroup.slice(0, -1)

    setComputerGroup(newCpGroup)
  }

  const onMonitorRemove = () => {
    let newMnGroup = [...monitorGroup]
    newMnGroup = newMnGroup.slice(0, -1)

    setMonitorGroup(newMnGroup)
  }

  return (<Layout title={title} authMode={authMode}>
    <Form form={form} onSubmit={onSubmit} labelWidth={100} style={{ padding: '15px 15%', }}>

      <FormItem label="Tencent ID" name="tencentId" labelAlign="left" initialData={tencentId_init} rules={[{ required: true, message: 'Required', type: 'warning' }]}>
        <Input placeholder='tencent ID' readonly style={{ width: "320px" }} />
      </FormItem>

      <FormItem label="First Day" name="boardingday" labelAlign="left" initialData={lastDay_init} rules={[{ required: true, message: 'Required', type: 'warning' }]}>
        <DatePicker mode="date" placeholder='' style={{ width: "320px" }} />
      </FormItem>

      <FormItem label="Department " name="dept" labelAlign="left" rules={[{ required: true, message: 'Required', type: 'warning' }]}>
        &nbsp;<Radio.Group options={deptOptions} ></Radio.Group>
      </FormItem>
      <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9" }}>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>Assets</span>
      </Divider>

      <Popup visible={visible} onVisibleChange={handleqrVisibleChange} placement="center">
        <div style={{ width: 400, height: 400 }}>
          {showQRScanner && (
            <QrReader
              onError={handleError}
              onResult={handleScan}
              constraints={{ facingMode: 'environment' }}
            />
          )}
        </div>
      </Popup>

      {computerGroup}
      <FormItem style={{ marginLeft: 100 }}>
        <Space direction='horizontal'>
          <Button size="small" type="button" variant="base" onClick={onComputerAdd}><AddIcon /></Button>
          <Button size="small" type="button" variant="base" onClick={onComputerRemove}><RemoveIcon /></Button>
        </Space>
      </FormItem>
      <br />
      {monitorGroup}

      <FormItem style={{ marginLeft: 100 }}>
        <Space direction='horizontal'>
          <Button size="small" type="button" variant="base" onClick={onMonitorAdd}><AddIcon /></Button>
          <Button size="small" type="button" variant="base" onClick={onMonitorRemove}><RemoveIcon /></Button>
        </Space>
      </FormItem>

      <br />
      <FormItem key={`asset-1000`} label={`Others`} labelAlign="left" name={`others`} style={{ width: '50%' }}>
        <Input placeholder='' />
      </FormItem>
      <FormItem name="onAck" initialData={false} requiredMark={true} rules={[{ required: true, message: 'Required', type: 'warning' }]}>
        <Checkbox /> &nbsp;&nbsp; I acknowledge that I have received all the above checked devices and accessories!
      </FormItem>
      <Space direction="horizontal">
        <div className='signature-panel'>
          <p>User Signature</p>
          <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadUser = ref }}
            canvasProps={{ width: 320, height: 180, className: 'sigCanvas' }} />
          <div>
            <Button size='small' variant="outline" theme="dashed" onClick={clearSigUser}>Clear</Button>
          </div>
        </div>
        <div className='signature-panel' style={{ display: "none" }}>
          <p>IT Signature</p>
          <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadIT = ref }}
            canvasProps={{ width: 210, height: 120, className: 'sigCanvas' }} />
          <div>
            <Button size='small' variant="outline" theme="dashed" onClick={clearSigIT}>Clear</Button>
          </div>
        </div>
      </Space>

      <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9", display: "none" }}>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>Admin Items</span>
      </Divider>
      <Space direction='horizontal' style={{ display: "none" }}>
        <FormItem label="" name="staffPass">
          <Checkbox /> &nbsp;&nbsp;Staff Pass
        </FormItem>
        <FormItem label="" name="accessCard">
          <Checkbox />&nbsp;&nbsp;Access Card
        </FormItem>
        <FormItem label="" name="pedestalKey">
          <Checkbox />&nbsp;&nbsp;Pedestal Keys
        </FormItem>
      </Space>
      <Divider align="center" dashed={false} layouts="horizontal" style={{ color: "#0052d9", display: "none" }}>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>Confirmation</span>
      </Divider>

      <Space direction="horizontal" style={{ display: "none" }}>
        <div className='signature-panel'>
          <p>HR Signature</p>
          <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadHR = ref }}
            canvasProps={{ width: 210, height: 120, className: 'sigCanvas' }} />
          <div>
            <Button size='small' variant="outline" theme="dashed" onClick={clearSigHR}>Clear</Button>
          </div>
        </div>
        <div className='signature-panel'>
          <p>Admin Signature</p>
          <SignatureCanvas penColor='green' backgroundColor='white' ref={(ref) => { sigPadAdmin = ref }}
            canvasProps={{ width: 210, height: 120, className: 'sigCanvas' }} />
          <div>
            <Button size='small' variant="outline" theme="dashed" onClick={clearSigAdmin}>Clear</Button>
          </div>
        </div>
      </Space>
      <br />
      <br />
      <br />
      <FormItem style={{ marginLeft: 100 }}>
        <Space>
          <Button type="submit" theme="primary">
            Submit
          </Button>
        </Space>
      </FormItem>
    </Form>
  </Layout>);
}
